<template>
  <widget-frame
    ref="root"
    :zoomed="false"
    :masked="masked"
    :is-loading="status === 'fetching'"
    :dev-mode="devMode"
    :has-errored="error != null"
    :banner="banner"
    class="nps-timeline"
  >
    <template #icon>
      <img class="header-icon" :src="icon" alt="Dashboard themes icon">
    </template>

    <template #header>
      Net Promoter Score (NPS)
    </template>

    <template #actions>
      <download-export-button
        :name="exportName + '-NPS-Timeline'"
        :is-loading="status === 'fetching'"
        :get-el="getTrendEl"
        :get-csv-data="getCsvData"
        :get-svg-export-config="getExportConfig"
        :make-ppt-slide="hasDate ? makePptSlide : undefined"
        short-name="NPS-Timeline"
        show-alerts
      ></download-export-button>
    </template>

    <template v-if="hasDate" #menu>
      <widget-menu
        :menus="menus"
        :vertical="false"
        :bound="$el"
        @onSelect="setMenuSelection"
      />
    </template>

    <template #devPanel>
      <div>
        Start: {{ new Date(startTime) }}<br />
        Done: {{ new Date(doneTime) }}<br />
        Elapsed: {{ (doneTime - startTime) / 1000 }} seconds<br />
        Status: {{ status }}<br />
        Error: {{ error }}
        <hr />
        <h2>this.props</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($props, null, 2) }}
        </code>
        <hr />
        <h2>this.data</h2>
        <code style="white-space: pre">
          {{ JSON.stringify($data, null, 2) }}
        </code>
      </div>
    </template>

    <template #error-panel>
      <div v-if="error" class="error-panel">
        <h3>
          <img
            class="errorIcon"
            :src="errorIcon"
            alt="widget error icon"
          />
          Opps, something went wrong while loading this widget.
        </h3>
        <div class="action">
          Try
          <button @click.stop="reload">
            reloading this widget
          </button>
          or
          <button @click.stop="refresh">
            reloading the page
          </button>
        </div>
        <div class="action">
          <button @click.stop="contact">
            Contact support
          </button>
          if the problem persists.
        </div>
        <div v-if="userError" class="message">
          {{ userError }}
        </div>
      </div>
    </template>

    <template v-if="overallStats" #content>
      <insight-cues
        v-if="getTimelineCues && hasDate && aiUse"
        :cues-stale="areTimelineCuesStale"
        :loading="timelineCuesLoading"
        :content="formattedTimelineCues"
        @fetch="fetchTimelineCues"
      ></insight-cues>
      <div class="statistic row">
        <div v-for="stat in overallStats" :key="stat.label" class="statistic" :class="stat.class">
          <div class="numeral">
            {{ getPrefix(stat.value, stat.percent) }}{{ stat.value.toFixed(2) }}{{ getSuffix(stat.percent) }}
          </div>
          <div class="label">
            {{ stat.label }}
          </div>
        </div>
      </div>
      <div v-if="selectedDataInPlottableFormat.length > 0" class="row timeline-container">
        <timeline
          v-if="hasDate"
          :timeline-id="'timeline-trend'"
          :all-series="selectedDataInPlottableFormat"
          :y-label="displayOptions.yAxisLabelLeft"
          :y-label-right="rightYAxisOptions.label"
          :series-labels="displayOptions.seriesLabels"
          :resolution="menuSelections['Resolution'].toLowerCase()"
          :y-value-number-format="displayOptions.numberType"
          :y-range="yRangeLeft"
          :y-range-right="yRangeRight"
          :x-label="menuSelections['Date Field']"
          :enable-legend="true"
          :records="records"
          y-axis-left-color="#068CCC"
          :y-axis-right-color="rightYAxisOptions.color"
          :y-axis-right-names="rightYAxisOptions.names"
          @series-visibility-changed="calculateYRange"
        ></timeline>
      </div>
    </template>
    <template v-else #content>
      <widget-message-panel>
        <template #title>
          <span>No Data</span>
        </template>
        <template #message>
          <span>There is not sufficient data to display this widget.</span>
        </template>
      </widget-message-panel>
    </template>
  </widget-frame>
</template>

<script lang="ts">
import PptxGenJS from 'pptxgenjs'
import { PropType, computed, defineComponent, onMounted, ref, watch } from 'vue'

import WidgetMenu from 'components/DataWidgets/WidgetMenu/WidgetMenu.vue'
import WidgetFrame from 'src/components/widgets/WidgetFrame/WidgetFrame.vue'
import icon from 'assets/img/dashboards/dash-satisfaction.svg'
import errorIcon from 'assets/icons/alert-bubble.svg'
import DownloadExportButton from 'components/project/analysis/results/widgets/DownloadExportButton.vue'
import Timeline from 'src/components/project/analysis/results/widgets/Timeline.vue'
import InsightCues from 'components/DataWidgets/InsightCues/InsightCues.vue'
import { getCsvData as CSVData, regroupData as regroup } from './NPSTimeline.utils'
import { getAggregationOffset, getDataAbsMax, makeDoubleTimelineSlide } from '../DataWidgetUtils'
import { WidgetMenuOptions } from 'src/types/components/WidgetMenu.types'
import { WidgetConfig } from 'src/types/DashboardTypes'
import { FetchState } from 'src/store/modules/data/mutations'
import { PivotData, Resolution, TrendLine } from 'src/types/widgets.types'
import { CurrentModelDateField } from 'src/types/ProjectTypes'
import WidgetMessagePanel from 'components/widgets/WidgetMessagePanel/WidgetMessagePanel.vue'
import { markdown } from 'src/utils/formatters'

export default defineComponent({
  components: {
    WidgetFrame,
    DownloadExportButton,
    Timeline,
    WidgetMenu,
    WidgetMessagePanel,
    InsightCues,
  },
  props: {
    /** data to render */
    data: {type: Object as PropType<PivotData>, required: false, default: null},
    overallData: {type: Object as PropType<FetchState<PivotData>>, required: false, default: null},
    exportName: {type: String, required: false, default: ''},
    /** `fetching`, `done` or `''` */
    status: {type: String, required: false, default: ''},
    /** error object for dev panel */
    error: {type: [Error, Object], required: false, default: null},
    /** nicer error message for user  */
    userError: {type: String, required: false, default: null},
    startTime: {type: Number, required: false, default: null},
    doneTime: {type: Number, required: false, default: null},
    devMode: {type: Boolean, required: false, default: false},
    /** widget banner to display */
    banner: { type: Object, default: ()=>null, required: false },
    dateFields: { type: Array as PropType<CurrentModelDateField[]>, required: true },
    defaultDateField: { type: String, required: false, default: null },
    weekStart: { type: String, required: false, default: null },
    group: { type: String, required: false, default: 'overall__' },
    /** Add a skeleton mask (used when reloading state between dashboards) */
    masked: { type: Boolean, required: false, default: false },
    config: { type: Object as PropType<WidgetConfig<'nps-timeline'> | null>, required: false, default: null },
    dayFirstDates: { type: Boolean, required: false, default: false },
    aiUse: { type: Boolean, required: false, default: false },
    getTimelineCues: { type: Boolean, required: false, default: false },
    timelineCues: { type: String, required: false, default: null},
    timelineCuesLoading: { type: Boolean, required: true, default: true},
    isStaff: { type: Boolean, required: false, default: false},
  },
  setup (props, { emit }) {
    const root = ref<InstanceType<typeof WidgetFrame> | null>(null)

    const selectedDataInPlottableFormat = ref<TrendLine[]>([])
    const records = ref({})
    const yRangeLeft = ref([0, 1])
    const yRangeRight = ref([0, 1])

    const lastEmittedReqs = ref<string>('')
    const lastCueReqs = ref<string>('')

    const menuSelections = ref({
      'Date Field': '',
      'Resolution': 'Monthly' as Resolution,
    })

    const displayOptions = ref({
      seriesLabels: {
        'impact': 'Impact on NPS',
        'nps': 'NPS',
        'promoters': 'Promoters %',
        'passives': 'Passives %',
        'detractors': 'Detractors %',
      },
      numberType: 'signAwareInteger',
      yAxisLabelLeft: 'NPS',
      yCapValue: 200,
      yMultipleOf: 5,
    })

    // If the timeline is being shown on the dashboard overview, then the data
    // is the overall data. This means we cannot calculate impact on X because
    // there is no subset of data to use in the calculation. (e.g if we were
    // showing the stats for a theme, the impact would be calculated using
    // the impact of the theme ON the overall dataset).
    const rightYAxisOptions = computed((): {
      label: string
      color: string
      names: string[]
    } => {
      if (hasImpact.value) {
        return {
          label: 'Impact on NPS',
          color: '#f89516',
          names: ['impact'],
        }
      }
      return {
        label: 'NPS Category (%)',
        color: '#7f7f7f',
        names: ['promoters', 'passives', 'detractors'],
      }
    })

    const hasImpact = computed(() => {
      return props.group !== 'overall__'
    })

    const formattedTimelineCues = computed((): string => {
      return props.timelineCues? markdown(props.timelineCues) : ''
    })

    const overallStats = computed((): {
      label: string
      class: string
      value: number
      percent: boolean
    }[] => {
      const data = props.overallData?.data?.payload
        .find(({ group__ }) => group__ === props.group)

      if (data && hasImpact.value) {
        return [
          {
            label: 'Impact on NPS',
            class: 'impact',
            value: +data['NPS Category|npsi_rto__'] ?? 0,
            percent: false,
          }, {
            label: 'NPS',
            class: 'nps',
            value: +data['NPS Category|nps__'] ?? 0,
            percent: false,
          },
        ]
      }
      if (data && !hasImpact.value) {
        return [
          {
            label: 'NPS',
            class: 'nps',
            value: +data['NPS Category|nps__'] ?? 0,
            percent: false,
          }, {
            label: 'Promoters',
            class: 'promoters',
            value: +data['NPS Category|Promoter%__'] ?? 0,
            percent: true,
          }, {
            label: 'Detractors',
            class: 'detractors',
            value: +data['NPS Category|Detractor%__'] ?? 0,
            percent: true,
          }, {
            label: 'Passives',
            class: 'passives',
            value: +data['NPS Category|Passive%__'] ?? 0,
            percent: true,
          },
        ]
      }
      return []
    })

    const menus = computed((): WidgetMenuOptions[] => {
      return [{
        name: 'Date Field',
        selection: menuSelections.value['Date Field'],
        options: [
          [{
            title: 'Date Field',
            type: 'menu',
            showSelected: true,
            selected: menuSelections.value['Date Field'],
            options: props.dateFields.map(({ name }) => name)
          }]
        ],
      }, {
        name: 'Resolution',
        selection: menuSelections.value['Resolution'],
        options: [
          [{
            title: 'Resolution',
            type: 'menu',
            showSelected: true,
            selected: menuSelections.value['Resolution'],
            options: ['Daily', 'Weekly', 'Monthly', 'Quarterly', 'Yearly']
          }]
        ],
      }]
    })

    const hasDate = computed(() => {
      return props.dateFields.length > 0
    })

    const areTimelineCuesStale = computed((): boolean => {
      return lastEmittedReqs.value !== lastCueReqs.value
    })

    const getPrefix = (n: number, percent: boolean) => {
      if (percent) return ''
      if (n > 0) return '+'
      return ''
    }

    const getSuffix = (percent: boolean) => {
      if (percent) return '%'
      return ''
    }

    const fetchData = (force = false) => {
      const blocks = [{
        'aggfuncs': [{
          'new_column': 'frequency',
          'src_column': 'document_id',
          'aggfunc': 'count'
        }, {
          'new_column': 'frequency',
          'src_column': 'document_id',
          'aggfunc': 'count'
        }],
        "pivot_field": "NPS Category",
        "metric_calculator": "nps"
      }]

      const requirements = {
        blocks,
        date_fieldname: menuSelections.value['Date Field'],
        date_aggregation_offset: getAggregationOffset(menuSelections.value['Resolution']),
        week_start: props.weekStart,
      }

      if (hasDate.value) {
        // Data by date resolution
        emit('requires',
          'nps-timeline',
          requirements,
          force,
          true
        )
      }

      // Overall data
      emit('requires',
        'nps-timeline-overall',
        {
          blocks: [{
            "aggfuncs": [{
              "new_column": "frequency",
              "src_column": "document_id",
              "aggfunc": "count"
            }],
            "pivot_field": "NPS Category",
            "metric_calculator": "nps"
          }]
        },
        force,
        true
      )
      lastEmittedReqs.value = JSON.stringify(requirements)
    }

    const refresh = () => {
      window.location.reload()
    }

    const contact = () => {
      try {
        window.Intercom('show')
      } catch {
        console.warn('intercom show failed')
      }
    }

    const reload = () => {
      fetchData(true)
    }

    const updateConfig = () => {
      const options: NonNullable<typeof props.config>['options'] = {
        dateField: menuSelections.value["Date Field"],
        resolution: menuSelections.value["Resolution"],
      }
      const updated = Object.assign({}, props.config, { options })
      emit('config-changed', updated)
    }

    const setMenuSelection = (_: unknown, path: [keyof typeof menuSelections.value, string], fromConfig=false) => {
      menuSelections.value[path[0]] = path[1] as any
      if (!fromConfig) updateConfig()
    }

    const setOptionsFromConfig = () => {
      setMenuSelection(null, ['Resolution', props.config?.options?.resolution ?? 'Monthly'], true)
      if (hasDate.value) {
        setMenuSelection(null, ['Date Field', props.config?.options?.dateField ?? (props.defaultDateField ?? props.dateFields[0].name)], true)
      } else {
        setMenuSelection(null, ['Date Field', ''], true)
      }
    }

    const calculateYRange = () => {
      const visibleDataset = selectedDataInPlottableFormat.value.filter(series => series.visible)
      if (visibleDataset.length === 0) return
      const dataMaxNPS = getDataAbsMax(
        [visibleDataset.find(({ name }) => name === 'nps')!],
        displayOptions?.value.yCapValue,
        displayOptions?.value.yMultipleOf,
      )
      yRangeLeft.value = [-dataMaxNPS, dataMaxNPS]

      if (hasImpact.value) {
        const dataMaxImpact = getDataAbsMax(
          [visibleDataset.find(({ name }) => name === 'impact')!],
          displayOptions?.value.yCapValue,
          displayOptions?.value.yMultipleOf,
        )
        yRangeRight.value = [-dataMaxImpact, dataMaxImpact]
      } else {
        // NPS Categories are percentages
        yRangeRight.value = [0, 100]
      }
    }

    const regroupData = (): void => {
      if (!hasDate.value) return
      const [ recs, groupedData ] = regroup(
        props.data,
        menuSelections.value['Date Field'],
        props.group,
        hasImpact.value ? ['nps', 'impact'] : ['nps', 'promoters', 'passives', 'detractors'],
      )
      // only show series with data
      selectedDataInPlottableFormat.value = groupedData.filter(({ counts=[] }) => counts.length > 0)
      records.value = recs
      calculateYRange()
    }

    const getCsvData = () => {
      return CSVData(selectedDataInPlottableFormat.value, menuSelections.value)
    }

    const fetchTimelineCues = async () => {
      const data = CSVData(selectedDataInPlottableFormat.value, menuSelections.value)
      if (props.isStaff) {
        console.log(selectedDataInPlottableFormat)
        console.log("NPS DATA", data)
      }
      emit(
        'timeline-cues',
        'nps',
        data,
        menuSelections.value["Date Field"],
        'nps_timeline_cues',
      )
    }

    const getTrendEl = () => {
      return root.value?.$el.querySelector('div.content')
    }

    const getExportConfig = () => {
      return {
        dims: getTrendEl()?.getBoundingClientRect(),
        css: `
          text {
            color: #383838;
            font-size: 14px;
            stroke: none;
          }
          .line {
            stroke-width: 2px;
          }
          .axis path, .axis line {
            shape-rendering: crispEdges;
            stroke: #ebebeb;
            stroke-width: 2px;
            opacity: 0.5;
          }
        `
      }
    }

    const makePptSlide = (pptx: PptxGenJS) => {
      if (!hasDate.value) return

      const slide = pptx.addSlide()
      makeDoubleTimelineSlide(
        pptx,
        slide,
        [
          selectedDataInPlottableFormat.value.slice(0, 1),
          selectedDataInPlottableFormat.value.slice(1),
        ],
        props.exportName + ' - NPS Timeline',
        ['NPS', 'NPS Category (%)'],
        props.dayFirstDates,
      )
    }

    watch(menuSelections, () => {
      fetchData()
    }, {
      deep: true,
    })

    watch(() => props.data, (val) => {
      if (val === null) return
      regroupData()
    })

    watch(() => props.config, () => {
      setOptionsFromConfig()
    }, {
      deep: true,
    })

    watch(() => props.group, () => {
      fetchData()
    })

    watch(() => props.timelineCues, () => {
      lastCueReqs.value = lastEmittedReqs.value
    })

    onMounted(() => {
      setOptionsFromConfig()

      if (props.data === null || props.overallData === null) {
        // regroupData and set OverallData will get called by the watchers
        // when the data is available
        fetchData()
      } else {
        // the data we need is available so we can immediately call the
        // wrangler functions
        regroupData()
      }
    })

    return {
      icon,
      errorIcon,
      refresh,
      contact,
      reload,
      getPrefix,
      getSuffix,
      overallStats,
      displayOptions,
      rightYAxisOptions,
      menuSelections,
      menus,
      root,
      getCsvData,
      getExportConfig,
      getTrendEl,
      hasDate,
      setMenuSelection,
      selectedDataInPlottableFormat,
      yRangeLeft,
      yRangeRight,
      records,
      calculateYRange,
      makePptSlide,
      fetchTimelineCues,
      formattedTimelineCues,
      areTimelineCuesStale,
    }
  },
})
</script>

<style lang="sass" scoped>
@import "assets/colours.sass"
@import '~assets/kapiche.sass'

.statistic.row
  margin-top: 14px
  min-width: 30%
  display: flex
  align-items: center
  justify-content: space-around
  .statistic
    font-size: 26px
    font-weight: bold
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    margin: 0px 10px
    &.impact
      color: $orange
    &.nps
      color: $blue
    &.promoters
      color: $green
    &.detractors
      color: $red
    &.passives
      color: $grey-dark
    .label
      margin: 10px
    .label
      font-size: 16px
      font-weight: normal

.header-icon
  height: 32px
  width: 100%

.row
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center

.stats
  display: flex
  flex-direction: row
  justify-content: space-evenly
  align-items: center

.stat
  font-size: 26px
  min-width: 5em
  font-weight: bold
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  flex-grow: 1

.label
  margin: 10px
  font-size: 16px
  font-weight: normal

::v-deep
  .legend-buttons
    display: none
  .timeline-legend-container
    column-count: 2
    margin-top: -6px
    li
      text-align: center
      .clickable-legend
        display: inline-flex

.error-panel
  display: flex
  flex-direction: column
  align-items: center
  font-size: 16px
  padding-bottom: 30px

.message
  display: flex
  flex-direction: row
  justify-content: center
  background-color: rgba(255, 0, 0, 0.1)
  padding: 6px
  color: $text-black
  width: 100%
  max-height: 30px
  position: absolute
  bottom: 0

.errorIcon
  position: relative
  height: 32px
  width: 32px
  display: inline-block
  top: 10px

.action
  padding-top: 20px

button
  background: none
  border: none
  border-bottom: 2px solid $blue
  padding: 3px 4px

  &:hover
    background-color: $grey-light

  &:focus
    border: 2px solid $blue-light
    outline: none

// These settings are required to allow the chart to resize correctly. If you comment these out,
// weird things happen with the sizing of the timeline.
.timeline-container
  width: inherit
  align-items: unset

</style>
