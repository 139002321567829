// General purpose utils for ScoreColumns, identified by column.type == 8 in SchemaColumns

import { SchemaColumn } from "src/types/SchemaTypes"

const topBoxRegex = /top (\d+) box/
const botBoxRegex = /bot (\d+) box/

export interface ScoreAggregation {
  type: "average" | "median" | "sum" | "top box" | "bot box"
  title: string
  boxVal?: number
}

export interface ScoreColumn {
  name: string,
  aggregation: ScoreAggregation
  range: number[]
  excludeOutOfRange: boolean
}

export const schemaColToScoreCol = (col: SchemaColumn): ScoreColumn => {
  let boxVal = 2
  let match
  if (match = col.score_aggregation?.match(topBoxRegex)) boxVal = parseInt(match[1])
  if (match = col.score_aggregation?.match(botBoxRegex)) boxVal = parseInt(match[1])
  let aggMap: {
    [key: string]: ScoreColumn
  } = {
    "average": {
      name: col.name,
      range: col.score_range ?? [1, 5],
      excludeOutOfRange: col.exclude_out_of_range ?? false,
      aggregation: {
        type: "average",
        title: "Average"
      }
    },
    "median": {
      name: col.name,
      range: col.score_range ?? [1, 5],
      excludeOutOfRange: col.exclude_out_of_range ?? false,
      aggregation: {
        type: "median",
        title: "Median"
      }
    },
    "sum": {
      name: col.name,
      range: col.score_range ?? [1, 5],
      excludeOutOfRange: col.exclude_out_of_range ?? false,
      aggregation: {
        type: "sum",
        title: "Sum"
      }
    },
  }
  aggMap[`top ${boxVal} box`] = {
    name: col.name,
    range: col.score_range ?? [1, 5],
    excludeOutOfRange: col.exclude_out_of_range ?? false,
    aggregation: {
      type: "top box",
      title: `Top ${boxVal} Box`,
      boxVal: boxVal
    }
  }
  aggMap[`bot ${boxVal} box`] = {
    name: col.name,
    range: col.score_range ?? [1, 5],
    excludeOutOfRange: col.exclude_out_of_range ?? false,
    aggregation: {
      type: "bot box",
      title: `Bottom ${boxVal} Box`,
      boxVal: boxVal
    }
  }
  return aggMap[col.score_aggregation ?? "average"]
}

// Simple function to generate box values for "top box" and "bot box" aggregation
export const getBoxVals = (boxVal: number, scoreRange: Array<number>, boxType: string) => {
  let boxVals = []
  if (boxType === "top box") {
    for (let i=scoreRange[1]; i > scoreRange[1]-boxVal; i--) {
      boxVals.push(i)
    }
  } else if (boxType === "bot box" ) {
    for (let i: number = scoreRange[0]; i < scoreRange[0]+boxVal; i++) {
      boxVals.push(i)
    }
  }
  return boxVals
}
