import { AnalyticsBrowser } from '@segment/analytics-next'
import { RouteLocationNormalized, Router } from 'vue-router'
import { Store } from 'vuex'
import { UserType } from "types/store/AuthModule.types"
import { SynonymType, QueryLocation } from 'types/Query.types'
import { DashboardConfig } from "types/DashboardTypes"

class AnalyticsWrapper {
  segment: AnalyticsBrowser
  store: Store<any> | undefined
  _router: Router | undefined

  set router (r: Router | undefined) {
    // Page tracking
    this._router = r
    if (r) {
      r.afterEach((to, from) => {
        // Make a page call for each navigation event
        this.segment.page('', (to.name || '') as string, {
          path: to.fullPath,
          referrer: from.fullPath
        })
      })
    }
  }

  get router (): Router | undefined {
    return this._router
  }

  constructor () {
    this.segment = AnalyticsBrowser.load({
      writeKey: process.env.SEGMENT_ID ?? '',
      cdnURL: 'https://segment.kapiche.com',
    }, {
      initialPageview: true,
      integrations: {
        'Segment.io': {
          apiHost: 'apisegment.kapiche.com/v1',
          protocol: 'https',
        },
      },
    })
  }

  get track () {
    if (!this.store || !this.router) {
      throw new Error('Analytics object is not initialised')
    }

    const domain: string = this.store.state.app.site?.domain
    const currentRoute: RouteLocationNormalized = this.router.currentRoute.value
    const currentProject: Record<string, unknown> | null = this.store.state.project.project
    const currentAnalysis: Record<string, unknown> = this.store.state.project?.analysis
    const currentDashboard: Record<string, unknown> = this.store.state.project?.dashboard
    const user: UserType = this.store?.state?.auth?.user
    const trackWrapper = (name: string, data: object): void => {
      this.segment.track(
        name,
        data,
        {
          context: {
            groupId: user?.is_staff ? 'Kapiche' : domain
          }
        }
      )
    }

    return {
      // Need to include these properties to ensure the getter cache is invalidated when they change
      _router: this.router,
      _store: this.store,
      /*
       * Unhandled errors.
       */
      errorUnhandled (serverErrors: string[]) {
        trackWrapper('Unhandled Error', {
          domain: domain,
          userType: user?.user_type,
          routeName: currentRoute.name,
          routePath: currentRoute.fullPath,
          serverErrors: JSON.stringify(serverErrors)
        })
      },
      /*
       * Connection error.
       */
      connectionError () {
        trackWrapper('Connection Error', {
          domain: domain,
          routeName: currentRoute.name,
          routePath: currentRoute.fullPath,
        })
      },
      userNavigation (elementName: string, extraAttrs={}) {
        trackWrapper('UserNavigation', {
          domain: domain,
          currentPage: currentRoute.name,
          userType: user?.user_type,
          uiElement: elementName,
          ...extraAttrs
        })
      },
      sideBarCollapse (elementName: string, extraAttrs={}) {
        trackWrapper('SideBarCollapse', {
          domain: domain,
          currentPage: currentRoute.name,
          userType: user?.user_type,
          uiElement: elementName,
          ...extraAttrs
        })
      },
      clickedReloadToast (extraAttrs={}) {
        trackWrapper('Clicked Reload Toast', {
          domain: domain,
          currentPage: currentRoute.name,
          userType: user?.user_type,
          ...extraAttrs
        })
      },
      newVersionDuringUserSession (extraAttrs={}) {
        trackWrapper('New Version During User Session', {
          domain: domain,
          currentPage: currentRoute.name,
          userType: user?.user_type,
          ...extraAttrs
        })
      },
      analysis: {
        /*
         * Downloading an image or csv export.
         */
        downloadExport (name: string, type: string, extraAttrs: Record<string, unknown>) {
          const attrs =  {
            domain: domain,
            userType: user?.user_type,
            name: name,
            type: type.toUpperCase()
          }
          if (extraAttrs) {
            Object.assign(attrs, extraAttrs)
          }
          trackWrapper('Download Export', attrs)
        },
        /*
         * Open analysis home page.
         */
        home (analysisDetails: Record<string, unknown>) {
          trackWrapper('View Analysis', Object.assign({
            domain: domain,
            projectId: currentProject?.id
          }, analysisDetails))
        },
        /**
         * The user has modified an analysis causing it to be rerun.
         */
        rerun (analysisSettings: Record<string, unknown>) {
          trackWrapper('Rerun Analysis', Object.assign({
            domain: domain,
            projectId: currentProject?.id,
          }, analysisSettings))
        },
        /**
         * The user has created an analysis that is now being run.
         */
        run (analysisSettings: Record<string, unknown>) {
          trackWrapper('Run Analysis', Object.assign({
            domain: domain,
            projectId: currentProject?.id
          }, analysisSettings))
        },
        /**
         * The user has updated an analysis with new data.
         */
        update (analysisId: string) {
          trackWrapper('Update Analysis', Object.assign({
            domain: domain,
            projectId: currentProject?.id,
            analysisId: analysisId
          }))
        },
        /**
         * The user has upgraded an analysis for new capabilities.
         */
        upgrade (analysisId: string) {
          trackWrapper('Upgrade Analysis', Object.assign({
            domain: domain,
            projectId: currentProject?.id,
            analysisId: analysisId
          }))
        },
        /**
         * View the unmapped records page.
         */
        viewUnmapped (numQueries: number, numResults: number, fractionUnmapped: number) {
            trackWrapper('Unmapped Verbatims', {
              domain: domain,
              queries: numQueries,
              unmappedVerbatims: numResults,
              fractionUnmapped: fractionUnmapped
            })
        },
        /**
         * User clicked on concept on storyboard
         */
        conceptClickedOnStoryboard (concept: string, projectId: number|null = null, analysisId: number|null = null): void {
          trackWrapper('Storyboard Concept Clicked', {
            domain: domain,
            project: projectId ?? currentProject?.id,
            analysis: analysisId ?? currentAnalysis?.id,
            concept: concept
          })
        }

      },
      dashboard: {
        /**
         * Analyst or viewer has opened a dashboard for viewing.
         */
        view (dashboardId: string, numThemes: number, isViewer: boolean, version: string, extraAttrs: Record<string, unknown>) {
          const attrs = {
            domain: domain,
            userType: user?.user_type,
            dashboardId: dashboardId,
            numThemes: numThemes,
            isViewer: isViewer,
            version: version
          }
          if (extraAttrs) {
            Object.assign(attrs, extraAttrs)
          }
          trackWrapper('View Dashboard', attrs)
        },
        /**
         * Analyst or viewer has drilled into a theme on the dasbhoard.
         */
        viewTheme (dashboardId: number, isViewer: boolean, queryId: number, name: string) {
          trackWrapper('View Dashboard Theme', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: dashboardId,
            isViewer: isViewer,
            queryId: queryId,
            name: name
          })
        },
        /**
         * Analyst or viewer has drilled into a theme group on the dasbhoard.
         */
        viewThemeGroup (dashboardId: number, isViewer: boolean, groupId: number, name: string) {
          trackWrapper('View Dashboard Theme', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: dashboardId,
            isViewer: isViewer,
            groupId: groupId,
            name: name
          })
        },
        /**
         * Analyst or viewer has drilled into a concept on the dasbhoard.
         */
        viewConcept (dashboardId: number, isViewer: boolean, concept: string) {
          trackWrapper('View Dashboard Concept', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: dashboardId,
            isViewer: isViewer,
            concept: concept
          })
        },
        /**
         * Dashboard filtered by date and/or segments (or filters are cleared).
         */
        applySegmentFilter (dashboardId: number, isViewer: boolean, fields: string[], numSegments: number, hasDateFilter: boolean) {
          trackWrapper('Dashboard Segment Filter', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: dashboardId,
            isViewer: isViewer,
            fields: fields,
            numSegments: numSegments,
            hasDateFilter: hasDateFilter
          })
        },
        /**
         * A row in the field segmentation widget was clicked to manually add a filter.
         */
        toggleSegmentFilter (dashboardId: number, field: string, segment: string): void {
          trackWrapper('Toggle Segment Filter', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: dashboardId,
            field: field,
            segment: segment
          })
        },
        /*
         * Invite a user to a dashboard.
         */
        inviteUser (email: string, dashboard: number, invitingUserEmail: string) {
          trackWrapper('Invite Dashboard User', {
            domain: domain,
            email: email,
            dashboard: dashboard,
            invitingUserEmail: invitingUserEmail
          })
        },
        /*
         * Add a user to a dashboard.
         */
        addUser (email: string, dashboardId: number, invitingUserEmail: string) {
          trackWrapper('Add Dashboard User', {
            domain: domain,
            email: email,
            dashboardId: dashboardId,
            invitingUserEmail: invitingUserEmail
          })
        },
        /**
         * User clicked the button to copy a shareable dashboard link.
         */
        linkCopied (dashboardId: number) {
          trackWrapper('Dashboard Link Copy', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: dashboardId
          })
        },
        /**
         * User clicked the "Save" button to update a Dashboard.
         */
        save (dashboardId: number) {
          trackWrapper('Dashboard Save', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: dashboardId
          })
        },
        /**
         * User clicked the "Save As" button to create a new Dashboard.
         */
        saveAs (oldDashboardId: number, newDashboardId: number) {
          trackWrapper('Dashboard Save As', {
            domain: domain,
            userType: user?.user_type,
            oldDashboardId: oldDashboardId,
            newDashboardId: newDashboardId
          })
        },
        /**
         * Zoomed a widget on the dashboard.
         */
        zoomWidget (dashboardId: number, isViewer: boolean, widget: string) {
          trackWrapper('Dashboard Zoom Widget', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: dashboardId,
            isViewer: isViewer,
            widget: widget
          })
        },
        /**
         * GroupBy operation used on the dashboard.
         */
        groupByAggregation (dashboardId: number, field: string) {
          trackWrapper('Dashboard GroupBy operation', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: dashboardId,
            field: field
          })
        },
        /**
         * User clicked the "Exit Viewer Mode" button from viewer dashboard.
         */
        explorerMode (dashboardId: number) {
          trackWrapper('Exit Viewer Mode', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: dashboardId
          })
        },
        /**
         * User clicks Generate AI Summary
         */
        tellAStory () {
          trackWrapper('Tell A Story', {
            domain: domain,
            userType: user?.user_type,
            page: currentRoute?.name,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
          })
        },
        timelineCues (dashboardId: number, timelineType: string) {
          trackWrapper('TimelineCues', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: dashboardId,
            timelineType: timelineType,
          })
        },
        digest: {
          modalOpened (dashboardId: number) {
            trackWrapper('Email Digest Modal Opened', {
              domain,
              userType: user?.user_type,
              dashboardId,
            })
          },
          outlierSettingChanged (dashboardId: number, value: boolean) {
            trackWrapper('Highlight Outlier Updated', {
              domain,
              userType: user?.user_type,
              dashboardId,
              value,
            })
          },
          summarySettingChanged (dashboardId: number, value: boolean) {
            trackWrapper('Verbatim Summary Updated', {
              domain,
              userType: user?.user_type,
              dashboardId,
              value,
            })
          },
          updated (digestId: number, dashboardId: number, formData: object) {
            trackWrapper('Email Digest Updated', {
              domain,
              userType: user?.user_type,
              digestId,
              dashboardId,
              ...formData,
            })
          },
          created (dashboardId: number, formData: object) {
            trackWrapper('Email Digest Created', {
              domain,
              userType: user?.user_type,
              dashboardId,
              ...formData,
            })
          },
          userUnsubscribed () {
            trackWrapper('Email Digest User Unsubscribed', {})
          },
          userResubscribed () {
            trackWrapper('Email Digest User Resubscribed', {})
          },
          deleted (digestId: number, dashboardId: number) {
            trackWrapper('Email Digest Deleted', {
              domain,
              userType: user?.user_type,
              digestId,
              dashboardId,
            })
          },
        },
        customization: {
          showWidgetsModal (dashboardId: number) {
            trackWrapper('Dashboard Widgets Modal Opened', {
              domain,
              dashboardId,
            })
          },
          widgetConfigApplied (dashboardId: number, config: DashboardConfig['widgets']) {
            trackWrapper('Widget configuration applied', {
              domain,
              dashboardId,
              config,
            })
          },
        }
      },
      // Emergent concepts widget
      // Exists across analysis and dashboard pages
      emergent: {
        /*
         * Change value displayed in the emergent concepts widget.
         */
        changeDisplay (value: string) {
          trackWrapper('Emergent Concepts Display', {
            domain: domain,
            userType: user?.user_type,
            page: currentRoute.name,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            value: value
          })
        },
        /*
         * Change date range considered in the emergent concepts widget.
         */
        changeRange (dateField: string, value: string) {
          trackWrapper('Emergent Concepts Range', {
            domain: domain,
            userType: user?.user_type,
            page: currentRoute.name,
            analysisId: currentAnalysis?.id,
            dateField: dateField,
            dashboardId: currentDashboard?.id,
            value: value
          })
        },
        /*
         * Change sorting on the emergent concepts widget.
         */
        changeSort (field: string, order: string) {
          trackWrapper('Emergent Concepts Order', {
            domain: domain,
            userType: user?.user_type,
            page: currentRoute.name,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            field: field,
            order: order
          })
        },
        /**
         * Tracking the number of results shown to the user.
         * Allows us to distinguish if user is getting an empty widget or not.
         */
        load (numResults: number) {
          trackWrapper('Emergent Concepts Load', {
            domain: domain,
            userType: user?.user_type,
            page: currentRoute.name,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            numResults
          })
        }
      },
      // Key Phrases widget
      keyphrases: {
        drillIntoPhrase (value: string) {
          trackWrapper('Key Phrases Drill Into', {
            domain: domain,
            userType: user?.user_type,
            page: currentRoute.name,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            value: value
          })
        },
        changeDisplay (value: string) {
          trackWrapper('Key Phrases Display', {
            domain: domain,
            userType: user?.user_type,
            page: currentRoute.name,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            value: value
          })
        },
        changeSort (field: string, order: string) {
          trackWrapper('Key Phrases Order', {
            domain: domain,
            userType: user?.user_type,
            page: currentRoute.name,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            field: field,
            order: order
          })
        },
        /**
         * Tracking the number of results shown to the user.
         * Allows us to distinguish if user is getting an empty widget or not.
         */
        load (numResults: number) {
          trackWrapper('Key Phrases Load', {
            domain: domain,
            userType: user?.user_type,
            page: currentRoute.name,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            numResults
          })
        }
      },
      integration: {
        /**
         * Data has been exported from an integration source that will
         * eventually be added to a project.
         */
        exportData (isCreate: boolean, provider: string, numRecords: number, numCols: number) {
          trackWrapper('Export from Integration', {
            type: isCreate ? 'create' : 'add',
            domain: domain,
            provider: provider,
            size: numRecords,
            numCols: numCols
          })
        },
        /*
         * User adds or updates an integration.
         */
        update (provider: string, numProjects: number) {
          trackWrapper('Update Integration', {
            domain: domain,
            provider: provider,
            numProjects: numProjects,
          })
        },
        /*
         * User removes an integration.
         */
        remove (provider: string, numProjects: number) {
          trackWrapper('Remove Integration', {
            domain: domain,
            provider: provider,
            numProjects: numProjects
          })
        }
      },
      project: {
        scoreConfig (config: {
          scoreName: string
          scoreRange: [number, number]
          scoreAggregation: string
          fieldIndex: number
          excludeOutOfRange: boolean
        }) {
          trackWrapper('Score column config set', {
            domain: domain,
            projectId: currentProject?.id,
            newProject: !currentProject?.id,
            ...config,
          })
        },
        /*
         * Data added to existing project.
         */
        addData (fileSize: number, numResponses: number, projectId: number) {
          trackWrapper('Data Added to Project', {
            domain: domain,
            projectId: projectId || currentProject?.id,
            fileSize: fileSize,
            numResponses: numResponses
          })
        },
        /*
         * Data deleted from existing project.
         */
        deleteData (errorFile: boolean, projectId: number) {
          trackWrapper('Data Deleted from Project', {
            domain: domain,
            errorFile: errorFile,
            projectId: projectId ?? currentProject?.id
          })
        },
        /*
         * Project has been created.
         */
        create (projectSettings: Record<string, unknown>) {
          trackWrapper('Create Project', Object.assign({
            domain: domain
          }, projectSettings))
        },
        /*
         * Existing project has been deleted.
         */
        delete (projectId: number) {
          trackWrapper('Delete Project', {
            domain: domain,
            projectId: projectId
          })
        },
        /*
         * File uploaded for create project.
         */
        uploadFile (fileSize: number, fileName: string, dataUnits: number, numCols: number) {
          trackWrapper('File Uploaded to Project', {
            domain: domain,
            fileSize: fileSize,
            fileName: fileName,
            dataUnits: dataUnits,
            numCols: numCols
          })
        },
        addOrUpdateCustomColumn (isAdded: boolean, sourceField : string, name: string, type: string, numTransformations: number): void {
          trackWrapper('Custom Column Updated', {
            domain: domain,
            isAdded: isAdded,
            sourceField: sourceField,
            name: name,
            type: type,
            numTransformations: numTransformations
          })
        },
        removeCustomColumn (name: string, type: string): void {
          trackWrapper('Custom Column Removed', {
            domain: domain,
            name: name,
            type: type
          })
        },
        uploadColumnTransformations (sourceField: string, name: string, type: string, numTransformations: number): void {
          trackWrapper('Column Transformations Uploaded', {
            domain: domain,
            sourceField: sourceField,
            name: name,
            type: type,
            numTransformations: numTransformations
          })
        },
        openCustomColumnsModal (): void {
          trackWrapper('Open Custom Columns Modal', {})
        },
      },
      themesWidget: {
        /*
         * Change data source displayed in the themes widget.
         */
        changeData (type: string, value: string) {
          trackWrapper('Themes Widget Data', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            type: type,
            value: value
          })
        },
        /*
         * Change value displayed in the themes widget.
         */
        changeDisplay (type: string, value: string) {
          trackWrapper('Themes Widget Display', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            type: type,
            value: value
          })
        },
        /*
         * Change sorting on the themes widget.
         */
        changeSort (order: string) {
          trackWrapper('Themes Widget Order', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            order: order
          })
        },
        /*
        * Change the setting that toggles between showing just the observed
        * data and showing the observed vs expected (if available)
        */
        changeShowExpected (value: boolean) {
          trackWrapper('Themes Widget Show Expected', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            value: value,
          })
        },
      },
      pivotTable: {
        exportCSV () {
          trackWrapper('Pivot Table exportCSV', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: currentDashboard?.id
          })
        },
        updateSettings (
          colourSchemeType: string,
          cellValueField: string,
          rowFields: string,
          columnFields: string,
        ) {
          trackWrapper('Pivot Table updateSettings', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: currentDashboard?.id,
            colourSchemeType,
            cellValueField,
            rowFields,
            columnFields,
          })
        },
        sortClick (
          vector: string,
          direction: string,
        ) {
          trackWrapper('Pivot Table sortClick', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: currentDashboard?.id,
            vector,
            direction,
          })
        },
      },
      correlationsWidget: {
        exportCSV () {
          trackWrapper('Correlations Widget exportCSV', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: currentDashboard?.id,
          })
        },
        drilldownTheme (theme: string) {
          trackWrapper('Correlations Widget drilldownTheme', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: currentDashboard?.id,
            theme,
          })
        },
        changeFields (type: string, value: string) {
          trackWrapper('Correlations Widget Fields', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: currentDashboard?.id,
            type: type,
            value: value
          })
        },
        changeMinPairFreq (type: string, value: string) {
          trackWrapper('Correlations Widget MinPairFreq', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: currentDashboard?.id,
            type: type,
            value: value
          })
        },
        changePairTypes (type: string, value: string[]) {
          trackWrapper('Correlations Widget PairTypes', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: currentDashboard?.id,
            type: type,
            value: value
          })
        },
        changeSort (field: string, asc: boolean, absolute: boolean) {
          trackWrapper('Correlations Widget Sort', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: currentDashboard?.id,
            field: field,
            asc: asc,
            absolute: absolute,
          })
        },
        excludeFromActionMenu (field: string) {
          trackWrapper('Correlations Widget excludeFromActionMenu', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: currentDashboard?.id,
            field: field,
          })
        },
        filterFromActionMenu (segments: string) {
          trackWrapper('Correlations Widget excludeFromActionMenu', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: currentDashboard?.id,
            segments: segments,
          })
        },
        openPivotTableFromActionMenu (col: string, row: string) {
          trackWrapper('Correlations Widget openPivotTableFromActionMenu', {
            domain: domain,
            userType: user?.user_type,
            dashboardId: currentDashboard?.id,
            col: col,
            row: row,
          })
        },
      },
      quadrantChart: {
        /*
         * Change value displayed in the quadrant chart.
         */
        changeDisplay (type: string, value: string) {
          trackWrapper('Quadrant Chart Display', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            type: type,
            value: value
          })
        },
        /*
         * Change field displayed on the quadrant chart.
         */
        changeField (type: string, value: string) {
          trackWrapper('Quadrant Chart Field', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            type: type,
            value: value
          })
        }
      },
      themeBuilder: {
        deleteTheme (queryId: number) {
          trackWrapper('Theme Builder - delete theme', {
            domain: domain,
            userType: user?.user_type,
            queryId: queryId,
          })
        },
        renameTheme (queryId: number, name: string) {
          trackWrapper('Theme Builder - rename theme', {
            domain: domain,
            userType: user?.user_type,
            queryId: queryId,
            name: name,
          })
        },
        ignoreConcept (concept: string) {
          trackWrapper('Theme Builder - ignore concept', {
            domain: domain,
            userType: user?.user_type,
            analysis: currentAnalysis?.id,
            concept,
          })
        },
        viewOnUnmapped (concept: string) {
          trackWrapper('Theme Builder - view on unmapped', {
            domain: domain,
            userType: user?.user_type,
            analysis: currentAnalysis?.id,
            concept,
          })
        },
        saveTheme (themeName: string, queryId: number) {
          trackWrapper('Theme Builder - save theme', {
            domain: domain,
            userType: user?.user_type,
            analysis: currentAnalysis?.id,
            themeName,
            queryId,
          })
        },
        createTheme (themeName: string, queryId: number) {
          trackWrapper('Theme Builder - create theme', {
            domain: domain,
            userType: user?.user_type,
            analysis: currentAnalysis?.id,
            themeName,
            query: queryId
          })
        },
        saveAsFromTheme (fromQueryId: number, newThemeName: string) {
          trackWrapper('Theme Builder - save as from theme', {
            domain: domain,
            userType: user?.user_type,
            analysis: currentAnalysis?.id,
            fromQueryId,
            newThemeName,
          })
        },
        discardAllChanges () {
          trackWrapper('Theme Builder - discard all changes', {
            domain: domain,
            userType: user?.user_type,
            analysis: currentAnalysis?.id,
          })
        },
      },
      query: {
        /*
         * Query was executed in compare mode.
         */
        compare (context: string, queryJson: string, numResults: number) {
          trackWrapper('Query Compare', {
            domain: domain,
            userType: user?.user_type,
            context: context,
            query: queryJson,
            numResults: numResults
          })
        },
        /*
         * Query was deleted.
         */
        delete (queryId: number) {
          trackWrapper('Query Deleted', {
            domain: domain,
            userType: user?.user_type,
            queryId: queryId
          })
        },
        /*
         * Query was renamed.
         */
        rename (queryId: number, name: string) {
          trackWrapper('Query Renamed', {
            domain: domain,
            userType: user?.user_type,
            queryId: queryId,
            name: name
          })
        },
        /*
         * Query was executed.
         */
        run (queryJson: string, numResults: number) {
          trackWrapper('Query', {
            domain: domain,
            userType: user?.user_type,
            query: queryJson,
            numResults: numResults
          })
        },
        /*
         * Query was saved.
         */
        save (queryId: number, name: string, queryJson: string) {
          trackWrapper('Query Saved', {
            domain: domain,
            userType: user?.user_type,
            queryId: queryId,
            name: name,
            query: queryJson
          })
        },
        /*
         * User clicked concept for query drilldown.
         */
        conceptDrilldown (conceptName: string, source: string) {
          trackWrapper('Query Concept Drilldown', {
            domain: domain,
            userType: user?.user_type,
            conceptName: conceptName,
            source: source
          })
        },
        /*
         * User clicked datetime point for query drilldown.
         */
        dateDrilldown (date: string, field: string, resolution: string) {
          trackWrapper('Query Date Drilldown', {
            domain: domain,
            userType: user?.user_type,
            date: date,
            field: field,
            resolution: resolution
          })
        },
        /*
         * User clicked NPS type for query drilldown.
         */
        npsDrilldown (npsType: string) {
          trackWrapper('Query NPS Drilldown', {
            domain: domain,
            userType: user?.user_type,
            npsType: npsType
          })
        },
        /*
         * User clicked segment on correlations chart for query drilldown.
         */
        segmentDrilldown (field: string, segment: string) {
          trackWrapper('Query Segment Drilldown', {
            domain: domain,
            userType: user?.user_type,
            field: field,
            segment: segment
          })
        },
        /*
         * User clicked sentiment type for query drilldown.
         */
        sentimentDrilldown (sentimentType: string) {
          trackWrapper('Query Sentiment Drilldown', {
            domain: domain,
            userType: user?.user_type,
            sentimentType: sentimentType
          })
        },
        /*
         * User selected a synonym suggestion.
         */
        synonymSelected (
          queryValues: Array<string>,
          synonynmValue: string,
          synonynmSimilarity: number,
          synoynmFrequency: number,
          synonymRank: number,
          location: QueryLocation,
        ) {
          trackWrapper('Query - Synonym Selected', {
            domain: domain,
            userType: user?.user_type,
            queryValues: queryValues,
            synonynmValue: synonynmValue,
            synonynmSimilarity: synonynmSimilarity,
            synoynmFrequency: synoynmFrequency,
            synonymRank: synonymRank,
            location,
          })
        },
        /*
         * User selected a batch of synonyms.
         */
        synonymBatchSelected (
          numPriorQueryValues: number,
          numSynonyms: number,
          synonyms: Array<SynonymType>,
          location: QueryLocation,
        ) {
          trackWrapper('Query Synonym Batch Selected', {
            domain: domain,
            numPriorQueryValues: numPriorQueryValues,
            numSynonyms: numSynonyms,
            synonymValues: synonyms.reduce((val, s) => val + (val.length ? ',' : '') + s.name, ''),
            synonymSimilarities: synonyms.reduce((val, s) => val + (val.length ? ',' : '') + s.similarity, ''),
            synonymFrequencies: synonyms.reduce((val, s) => val + (val.length ? ',' : '') + s.frequency, ''),
            location,
          })
        },
      },
      scoreTimeline: {
        changeField (section: string, value: string) {
          trackWrapper('Score Timeline Field', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            section,
            value,
          })
        },
      },
      segmentationChart: {
        /*
         * Change field displayed on the segmentation chart.
         */
        changeField (field: string, queryName: string) {
          let eventData: Record<string, unknown> = {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            field: field
          }
          if (queryName) {
            eventData.queryName = queryName
          }
          trackWrapper('Segmentation Chart Field', eventData)
        },
        /*
         * Change order of items on the segmentation chart.
         */
        changeOrder (order: string, queryName: string) {
          let eventData: Record<string, unknown> = {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            order: order
          }
          if (queryName) {
            eventData.queryName = queryName
          }
          trackWrapper('Segmentation Chart Order', eventData)
        },
        /*
        * Change the setting that toggles between showing just the observed
        * data and showing the observed vs expected (if available)
        */
        changeShowExpected (value: boolean) {
          trackWrapper('Segmentation Chart Show Expected', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            value: value,
          })
        },
      },
      site: {
        /*
         * Copy themes from one analysis to another.
         */
        copyQueries (
          sourceAnalysis: Record<string, unknown>,
          targetAnalysis: Record<string, unknown>,
          deleteExistingQueries: boolean,
          skipUnstructured: boolean,
          addToDashboards: boolean,
          selectedQueries: number[],
          resultDetails: Record<string, number>,
          overwriteExistingQueries: boolean,
        ) {
          const attrs =  {
            domain: domain,
            sourceAnalysisId: sourceAnalysis.id,
            targetAnalysisId: targetAnalysis.id,
            sourceAnalysisName: sourceAnalysis.name,
            targetAnalysisName: targetAnalysis.name,
            deleteExistingQueries: deleteExistingQueries,
            skipUnstructured: skipUnstructured,
            addToDashboards: addToDashboards,
            selectedQueries: selectedQueries,
            overwriteExistingQueries: overwriteExistingQueries,
          }
          Object.assign(attrs, resultDetails)
          // Note: The "Copy Queries" feature has been renamed to "Copy Themes",
          // but we're keeping the event name the sake of the analytics history.
          trackWrapper('Copy Queries', attrs)
        },
        /*
         * Add an approved domain.
         */
        addApprovedDomain (approvedDomain: string) {
          trackWrapper('Add Approved Domain', {
            domain: domain,
            approvedDomain: approvedDomain
          })
        },
        /*
         * Remove an approved domain.
         */
        removeApprovedDomain (approvedDomain: string) {
          trackWrapper('Remove Approved Domain', {
            domain: domain,
            approvedDomain: approvedDomain
          })
        },
        /*
         * Invite a user to the site.
         */
        inviteUser (email: string, type: string) {
          trackWrapper('Invite New User', {
            domain: domain,
            email: email,
            type: type
          })
        },
        /*
         * Viewer user visits site home screen.
         */
        viewerHome (userId: number, numDashboards: number) {
          trackWrapper('Viewer Site Home', {
            domain: domain,
            userType: user?.user_type,
            userId: userId,
            numDashboards: numDashboards
          })
        },
        /*
         * Admin/Analyst visits subscription
         */
        subscriptionView () {
          trackWrapper('Subscription visit', {
            domain: domain,
          })
        },

        userTypeChange (email: string, old_type: string, new_type: string) {
          trackWrapper('User type changed', {
            domain: domain,
            user: email,
            old_type,
            new_type
          })
        },

        userAccountDisabled (email: string, disabled: boolean) {
          trackWrapper('User account disable changed', {
            domain: domain,
            user: email,
            disabled,
          })
        },
      },
      timeline: {
        /*
         * Change value displayed on the timeline.
         */
        changeDisplay (type: string, value: string) {
          trackWrapper('Timeline Display', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            type: type,
            value: value
          })
        },
        /*
         * Change data displayed on the timeline.
         */
        changeData (type: string, value: string) {
          trackWrapper('Timeline Data', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            type: type,
            value: value
          })
        },
        /*
         * Change resolution of the timeline.
         */
        changeResolution (resolution: string) {
          trackWrapper('Timeline Resolution', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            resolution: resolution
          })
        },
      },
      verbatimsWidget: {
        /**
         * Change ranking approach.
         */
        changeOrder (isDashboard: boolean, orderBy: string) {
          trackWrapper('Verbatims Widget Order', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            isDashboard: isDashboard,
            orderBy: orderBy
          })
        },
        /**
         * Change number verbatims shown per page.
         */
        changePageLimit (isDashboard: boolean, pageLimit: number) {
          trackWrapper('Verbatims Widget Page Limit', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            isDashboard: isDashboard,
            pageLimit: pageLimit
          })
        },
        /**
         * Navigate between pages.
         */
        paginate (
          isDashboard: boolean,
          pageLimit: number,
          startIndex: number,
        ) {
          trackWrapper('Verbatims Widget Paginate', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            dashboardId: currentDashboard?.id,
            isDashboard: isDashboard,
            pageLimit: pageLimit,
            startIndex: startIndex
          })
        },
        /*
         * User clicked show info on a verbatim.
         */
        showInfo (isDashboard: boolean) {
          trackWrapper('Verbatims Widget Show Info', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            isDashboard: isDashboard
          })
        },
        /**
         * Toggle concept highlighting.
         */
        toggleHighlight (isDashboard: boolean, highlight: boolean) {
          trackWrapper('Verbatims Widget Highlight', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            isDashboard: isDashboard,
            highlight: highlight
          })
        },
        /**
         * User updated sentiment on verbatim
         */
        updateSentiment (sentimentType: string, projectId: number|null = null, analysisId: number|null = null): void {
          trackWrapper('Sentiment Updated on Verbatim', {
            domain: domain,
            projectId: projectId ?? currentProject?.id,
            analysisId: analysisId ?? currentAnalysis?.id,
            sentimentType: sentimentType
          })
        }
      },
      // Legacy Segment and Topic correlations on query page
      correlationWidget: {
        /**
         * Change what is displayed.
         */
        changeDisplay (widgetType: string, display: string) {
          trackWrapper('Correlation Widget Display', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            widgetType: widgetType,
            display: display
          })
        },
        /**
         * Change mode (metric).
         */
        changeMode (widgetType: string, mode: string) {
          trackWrapper('Correlation Widget Mode', {
            domain: domain,
            userType: user?.user_type,
            analysisId: currentAnalysis?.id,
            widgetType: widgetType,
            mode: mode
          })
        }
      },
      /** Analytics tracking user usage of the project labels */
      labels: {
        filteredProjectList (nLabels: number) {
          trackWrapper('Filtered project list by label', {
            domain: domain,
            userType: user?.user_type,
            nLabels,
          })
        },
        createdNewLabel () {
          trackWrapper('Created new label for the site', {
            domain: domain,
            userType: user?.user_type,
          })
        },
        updateLabel (propertiesUpdated: string[]) {
          trackWrapper('Updated existing label for the site', {
            domain: domain,
            userType: user?.user_type,
            propertiesUpdated,
          })
        },
        deleteLabel () {
          trackWrapper('Delete a label for the site', {
            domain: domain,
            userType: user?.user_type,
          })
        },
        addLabelToProject () {
          trackWrapper('User added label to project', {
            domain: domain,
            userType: user?.user_type,
          })
        },
        removeLabelFromProject () {
          trackWrapper('User added label to project', {
            domain: domain,
            userType: user?.user_type,
          })
        }
      }
    }
  }
}

// Really helpful debugger for segment events
// Lifted from https://gist.github.com/alexgb/7456209
function setupSegmentDebugger (segment: AnalyticsBrowser): void {
  const _asAnalyticsMethodColors = {
    identify: "#c66",
    group: "#c66",
    alias: "#c66",
    track: "#66c",
    page: "#c6c",
  }

  type AnalyticsMethods = keyof typeof _asAnalyticsMethodColors

  function _asAnalyticsLog (method: AnalyticsMethods, objects: any[]) {
    const color = _asAnalyticsMethodColors[method] || "#888"
    const args = ['%c Segment.io %c '+method+' ', 'background: #6c6; color: white;', 'background: '+color+'; color: white;']
    objects = objects || []
    console.log(...args.concat(objects))
  }

  const segmentIsLoaded = (Array.from(document.querySelectorAll('script[src]')) as HTMLScriptElement[])
    .filter((script) => script.src.indexOf('analytics.js') !== -1).length

  const keys = Object.keys(_asAnalyticsMethodColors) as AnalyticsMethods[]
  keys
    .filter((key) => key.indexOf('_') !== 0) //ignore private keys
    .forEach((method) => {
      const logAnalytics = function (...args: any) {
        return _asAnalyticsLog(method, Array.prototype.slice.call(args, 0))
      }

      if (segmentIsLoaded) {
        segment.on(method, logAnalytics)
      } else {
        segment[method] = logAnalytics as any
      }
    })

  console.log('Segment debugger loaded')
}

const analytics = new AnalyticsWrapper()

export {
  AnalyticsWrapper as Analytics,
  setupSegmentDebugger,
  analytics,
}
