import { WidgetMenuOptions } from "types/components/WidgetMenu.types"
import {Block, Requirements} from "types/PivotData.types"
import { ChrysalisQueryType, SavedQuery } from "types/Query.types"
import {
  QuadrantDataCSVType,
  QuadrantDataRowType,
  QuadrantWidgetPayloadItem,
  MinMaxType
} from "types/components/QuadrantWidget.types"

import { QuadrantChartDot } from "types/components/Charts.types"
import { ScoreColumn, getBoxVals } from "src/utils/score"

export type SortMethod = 'highest X' | 'lowest X' | 'highest Y' | 'lowest Y'

export enum Limit {
  SELECTION,
  DATA,
  FULL
}

export const quadColors = {
  red:    'rgb(255, 217, 217)',
  green:  'rgb(220, 245, 225)',
  yellow: 'rgb(252, 244, 232)',
  blue:   'rgb(233, 249, 255)'
}

export const backgroundColors = {
  green: '#f2fbf4',
  red: '#fffafa',
  yellow: '#fdf9f3',
  grey: '#f7f7f7',
  lightGrey: '#F8F9FF'
}

export const pointColors = {
  yellow: '#f89516',
  red: '#ee3824',
  green: '#21ba45',
  blue: '#068ccc',
  grey: '#7f7f7f'
}


export const minMax = (dots: number[], pad=5): MinMaxType => {
  const padding = !pad ? 1 : Math.abs(pad)
  return {
    min: (Math.floor(Math.min(...dots)/padding))*padding,
    max: (Math.ceil(Math.max(...dots)/padding))*padding
  }
}



// Helper for calculating the color of a point based on quadrant
// Round up when calculating color quadrant (0 is on the positive color side)
export const dotColor = (
  x: number,
  y: number,
  display: string,
  npsField: string,
  yMinMax: MinMaxType,
  xMinMax: MinMaxType,
):string => {
  const xMid = (xMinMax.max - xMinMax.min) / 2 + xMinMax.min
  const yMid = (yMinMax.max - yMinMax.min) / 2 + yMinMax.min
    switch (display) {
    case 'Detractors':
    case 'Negative Sentiment':
      return pointColors.red
    case 'Promoters':
    case 'Positive Sentiment':
      return pointColors.green
    case 'Passives':
    case 'Neutral Sentiment':
      return pointColors.grey
    case 'Mixed Sentiment':
      return pointColors.yellow
    case npsField:
      return pointColors.blue
    default:
      return pointColors.blue
  }
}

export const quadrantColors = (selectedDisplay: string, NpsField:string): Record<string, string> => {
  switch (selectedDisplay) {
    case NpsField:
      return {
        topLeft: quadColors.red,
        topRight: quadColors.green,
        bottomLeft: quadColors.yellow,
        bottomRight: quadColors.blue
      }
    case 'Detractors':
    case 'Negative Sentiment':
      return  {
        topLeft: backgroundColors.red,
        topRight: backgroundColors.red,
        bottomLeft: backgroundColors.red,
        bottomRight: backgroundColors.red
      }
    case 'Promoters':
    case 'Positive Sentiment':
      return  {
        topLeft: backgroundColors.green,
        topRight: backgroundColors.green,
        bottomLeft: backgroundColors.green,
        bottomRight: backgroundColors.green
      }
    case 'Passives':
    case 'Neutral Sentiment':
      return  {
        topLeft: backgroundColors.grey,
        topRight: backgroundColors.grey,
        bottomLeft: backgroundColors.grey,
        bottomRight: backgroundColors.grey
      }
    case 'Mixed Sentiment':
      return  {
        topLeft: backgroundColors.yellow,
        topRight: backgroundColors.yellow,
        bottomLeft: backgroundColors.yellow,
        bottomRight: backgroundColors.yellow
      }
    default:
      return  {
        topLeft: backgroundColors.lightGrey,
        topRight: backgroundColors.lightGrey,
        bottomLeft: backgroundColors.lightGrey,
        bottomRight: backgroundColors.lightGrey
      }
  }
}


export const menus = (
    selectedDisplay: string | null,
    selectedData: string,
    sortedSegmentsPerField: Record<string, string[]>,
    hasNps: boolean,
    hasSentiment: boolean,
    hasNumericFields: boolean,
    NpsField: string | null,
    numericalFields: string[],
    scoreFields: string[],
    isZoomed: boolean,
    limit: Limit,
    hasQueries: boolean,
    featureFlags: Record<string, boolean>,
): WidgetMenuOptions[] => {
  const otherOptions = featureFlags?.theme_groups
    ? ['Themes', 'Theme Groups', 'Top Concepts']
    : ['Themes', 'Top Concepts']

  let menus: WidgetMenuOptions[] = [
    {
      name: 'Data',
      selection: selectedData,
      options: [
        [
          {
            title: 'Fields',
            type: 'menu',
            showSelected: true,
            selected: otherOptions.includes(selectedData) ? null : selectedData,
            options: sortedSegmentsPerField ? Object.keys(sortedSegmentsPerField).filter(f=>f !== 'NPS Category').map(f=>f) : []
          }
        ],
        [
          {
            title: 'Other',
            type: 'menu',
            showSelected: true,
            selected: otherOptions.includes(selectedData) ? selectedData : null,
            options: otherOptions.map(option => {
              return !hasQueries && option === 'Themes'
                ? {
                  label: 'Themes',
                  value: 'Themes',
                  disabled: true,
                  tooltip: 'There are no Themes in this Analysis',
                }
                : option
            }),
          }
        ],
      ]
    },
    {
      name: 'Display',
      selection: selectedDisplay || '',
      options: []
    },
  ]

  if (hasNumericFields) {
    menus[1].options!.push([
      {
        title: 'Numerical Field',
        type: 'menu',
        showSelected: true,
        selected: numericalFields.includes(selectedDisplay || '') ? selectedDisplay : null,
        options:  numericalFields,
      }])
  }

  if (scoreFields.length) {
    menus[1].options!.push([
      {
        title: 'Score',
        type: 'menu',
        showSelected: true,
        selected: scoreFields.includes(selectedDisplay || '') ? selectedDisplay : null,
        options:  scoreFields,
      }])
  }

  if (hasNps) {
    menus[1].options!.push([
      {
        title: 'NPS',
        type: 'menu',
        showSelected: true,
        selected: [NpsField, 'Detractors', 'Passives', 'Promoters'].includes(selectedDisplay) ? selectedDisplay : null,
        options: [NpsField || '', 'Detractors', 'Passives', 'Promoters'],
      }
    ])
  }

  if (hasSentiment) {
    menus[1].options!.push([
      {
        title: 'Sentiment',
        type: 'menu',
        showSelected: true,
        selected: [
            'Positive Sentiment', 'Negative Sentiment', 'Neutral Sentiment', 'Mixed Sentiment'
          ].includes(selectedDisplay || '') ? selectedDisplay : null,
        options: [ 'Positive Sentiment', 'Negative Sentiment', 'Neutral Sentiment', 'Mixed Sentiment' ],
      }
    ])
  }

  if (isZoomed) {

    menus.reverse()

    const limitOptions = [{ label: 'Selection', value: Limit.SELECTION}, { label: 'All Data', value: Limit.DATA}, {label: '100%', value: Limit.FULL}]
    menus.push(
      {
        name: 'Zoom to',
        selection: limitOptions[limit].label,
        options: [[
          {
            type: 'menu',
            showSelected: true,
            selected: limit,
            options: limitOptions
          }
        ]]
      }
    )
  }

  return menus
}

export const baseRequirements = (
  hasNps: boolean,
  hasSentiment: boolean,
  numericalFields: string[] | null,
  selectedField: string,
  queries: SavedQuery[] = [],
  queryLimit: number,
  display: string,
  scoreColumn: ScoreColumn | null,
) : Record<string, Requirements> => {
  let blocks: Block[] = [{
    aggfuncs: [
      {
        new_column: 'frequency',
        src_column: 'document_id',
        aggfunc: 'count',
      }
    ],
  }]

  // get NPS
  if (hasNps) {
    blocks.push({
    aggfuncs: [
      {
        new_column: 'frequency',
        src_column: 'document_id',
        aggfunc: 'count',
      }
    ],
    pivot_field: 'NPS Category',
    metric_calculator: 'nps',
    })
  }

  // get sentiment
  if (hasSentiment) {
    blocks.push({
      aggfuncs: [
      {
        new_column: 'frequency',
        src_column: 'document_id',
        aggfunc: 'count',
      },
    ],
    pivot_field: 'sentiment__',
    metric_calculator: 'sentiment',
    })
  }

  if (scoreColumn) {
    if (["top box", "bot box"].includes(scoreColumn.aggregation.type)) {
      let boxVal = scoreColumn.aggregation.boxVal ?? 2
      let boxValues = getBoxVals(boxVal, scoreColumn.range, scoreColumn.aggregation.type)
      blocks.push({
        'pivot_field': scoreColumn.name,
        'aggfuncs': [{
          'new_column': 'frequency',
          'src_column': 'document_id',
          'aggfunc': 'count',
        }],
        'metric_calculator': {
          'type': 'box',
          'field': scoreColumn.name,
          'box_values': boxValues
        },
      })
    } else if (["average", "sum", "median"].includes(scoreColumn.aggregation.type)) {
      let agg = scoreColumn.aggregation.type === "average"? "mean": scoreColumn.aggregation.type
      blocks.push({
        'aggfuncs': [
          {
            'new_column': 'aggVal|count',
            'src_column': scoreColumn.name,
            'aggfunc': 'count'
          }, {
            'new_column': 'aggVal|mean__',
            'src_column': scoreColumn.name,
            'aggfunc': agg,
          }
        ],
        'metric_calculator': 'mean_impact',
      })
    }
  }

  // get numerics
  if (numericalFields?.length) {
    blocks.push({
      aggfuncs: numericalFields.map(field=>(
        {
          new_column: `${field}|mean__`,
          src_column: `${field}`,
          aggfunc: 'mean',
        }
      ))
    })
  }

  const queryList = queryLimit
    ? queries.slice(0, queryLimit) || []
    : queries || []

  let request_queries: ChrysalisQueryType[] =
    queryList.map((q: SavedQuery) => {
      let name = q.name
      if (display === 'Themes') {
        name = `q_${q.id}`
      }
      if (display === 'Theme Groups') {
        name = `group_${q.id}`
      }
      return {
        name,
        value: q.query_value
      }
    })

  return {
    'queries': { blocks, queries: request_queries  },
    'fields': { blocks, agg_fields: selectedField ? [selectedField] : [] }
  }
}

export const overallRow = (queryPayload: QuadrantWidgetPayloadItem[]): QuadrantWidgetPayloadItem|null  => {
  if (!queryPayload) return null
  const overallData = queryPayload?.find(p=>p.group__ === 'overall__')
  return overallData || null
}

export const rowsFromPayload = (queryPayload: QuadrantWidgetPayloadItem[], fieldsPayload: QuadrantWidgetPayloadItem[], selectedData: string): QuadrantDataRowType[] => {
  const otherOptions = ['Themes', 'Theme Groups', 'Top Concepts']
  if (!queryPayload || (!otherOptions.includes(selectedData) && !fieldsPayload)) return []
  let data = []
  // needed for frequency calculation below
  const overallData = overallRow(queryPayload)
  if (!overallData) return []

  if (otherOptions.includes(selectedData)) {
    data = queryPayload
  } else {
    data = fieldsPayload.filter(p=>p.group__=== 'overall__')
  }
  if (!data) return []

  const getLabel = (dataPoint: QuadrantWidgetPayloadItem) => {
    if (!otherOptions.includes(selectedData)) {
      return dataPoint[selectedData]
    }

    return dataPoint['group__']
  }

  return data.map(d=>(
    {
      ...d,
      label: getLabel(d),
      records: d.frequency,
      frequency: d.frequency/overallData.frequency
    } as QuadrantDataRowType
  ))
}

export const rowsToCsv = (
  rows: QuadrantDataRowType[],
  displayType: string,
  selectedData: string,
  selectedDisplay: string,
  visibleDots: QuadrantChartDot[],
  dotNameMap: Record<number, string>,
  groupLabelMap: Record<number, string>,
): QuadrantDataCSVType[] => {
  const isThemesOrGroups = ['Themes', 'Theme Groups'].includes(selectedData)
  const visibleLabels = visibleDots.map((d) =>
    d[isThemesOrGroups ? 'id' : 'label']
  )

  return rows.filter((r) => visibleLabels.includes(r.label)).map((row) => {
    let label = row.label

    if (isThemesOrGroups) {
      const id = selectedData === 'Themes'
        ? Number(row.label.replace(/^q_/, ''))
        : Number(row.label.replace(/^group_/, ''))

      label = dotNameMap[id]

      if (groupLabelMap[id]) {
        label += ` [${groupLabelMap[id]}]`
      }
    }

    let csvRow = {
      [selectedData]: label,
      Records: row.records,
      Frequency: (row.frequency).toFixed(2)
    }

    switch (displayType) {
      case 'NPS':
          csvRow = {
            ...csvRow,
            NPS: row['NPS Category|nps__']?.toFixed(2),
            Promoters: (row['NPS Category|Promoter%__']/100)?.toFixed(2),
            Detractor: (row['NPS Category|Detractor%__']/100)?.toFixed(2),
            Passive: (row['NPS Category|Passive%__']/100)?.toFixed(2),
            ['No NPS']: (row['NPS Category|(No Value)%__']/100)?.toFixed(2),
          }
        break
      case 'Numerical Field':
        csvRow[selectedDisplay] = row[`${selectedDisplay}|mean__`] ? row[`${selectedDisplay}|mean__`] : 0
        break
      case 'Sentiment':
        csvRow = {
          ...csvRow,
          PositiveSentiment: row[`sentiment__|positive%__`]?.toFixed(2),
          NegativeSentiment: row[`sentiment__|negative%__`]?.toFixed(2),
          MixedSentiment: row[`sentiment__|mixed%__`]?.toFixed(2),
          NeutralSentiment: row[`sentiment__|neutral%__`]?.toFixed(2),
        }
        break
      default:
        break
    }
    return csvRow
  })
}
