<template>
  <div
    v-if="showMessage"
    class="session-banner"
    :class="{
      'with-sidebar': hasSidebar,
    }"
  >
    <span>
      Your session will expire in {{ timeDisplay }}, <a href="" @click.prevent="LOGIN">click here to reauthenticate</a>.
    </span>
  </div>
</template>
<script lang="ts">
  import { defineComponent } from 'vue'
  import { mapActions, mapGetters } from 'vuex'
  import dayjs from 'dayjs'
  import { LOGIN } from 'src/store/types'

  export default defineComponent({
    props: {
      hasSidebar: { type: Boolean, default: false }
    },
    data () {
      return {
        timer: null,
        timeLeft: 0,
      }
    },
    computed: {
      ...mapGetters(['currentSite', 'hasRequestErrors', 'serverErrors', 'hasConnectionErrors']),
      showMessage () {
        return this.timeLeft > 0
      },
      timeDisplay () {
        return dayjs.duration(this.timeLeft * 1000).format('mm:ss')
      },
    },
    mounted () {
      this.startCountdown()
    },
    methods: {
      ...mapActions({ LOGIN }),
      startCountdown () {
        clearInterval(this.timer)
        this.timer = setInterval(() => {
          if (this.$auth?.refreshTokenExpired?.()) {
            const currentTime = Math.floor(Date.now() / 1000)
            this.timeLeft = (this.$auth.idTokenClaims?.exp ?? currentTime) - currentTime
          } else {
            this.timeLeft = 0
          }
        }, 1000)
      },
    },
  })
</script>
<style lang="sass" scoped>
  @import '../../assets/kapiche.sass'
  @import '../../assets/mixins.sass'

  .session-banner
    background-color: $blue
    color: white
    font-size: rem(16px)
    font-weight: bold
    padding-top: rem(25px)
    position: fixed
    height: rem(75px)
    text-align: center
    top: 0
    width: 50%
    z-index: 50
    &.connection-error
      background-color: $subdued
    &.with-sidebar
      height: rem(70px)
      left: 50rem
      width: calc(60% - 35rem)

    .icon-delete-thin
      cursor: pointer
      font-size: rem(12px)
      margin-left: rem(10px)
    a
      color: #fffa
</style>
