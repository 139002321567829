<template>
  <div class="dot-container">
    <div class="dots-grid">
      <div
        v-for="(dot, index) in visibleDots"
        :key="dot.id"
        class="dot-item"
      >
        <span class="number">{{ index + 1 }}</span>
        <span class="dot" :style="{ backgroundColor: dot.color }"></span>
        <span class="label" :title="dot.label">{{ dot.label }}</span>
      </div>
    </div>
    <button
      v-if="dots.length > dotsVisible"
      @click="$emit('show-all')"
    >
      Show {{ dots.length - dotsVisible }} more concepts
    </button>
  </div>
</template>

<script lang="ts">
import { computed, PropType } from 'vue'
import { QuadrantChartDot } from "src/types/components/Charts.types"

export default {
  props: {
    dots: {
      type: Array as PropType<QuadrantChartDot[]>,
      required: true,
    },
    dotsVisible: {
      type: Number as PropType<number>,
      default: 5,
    }
  },
  setup (props) {
    const visibleDots = computed(() => {
      return props.dots.slice(0, props.dotsVisible)
    })

    return {
      visibleDots,
    }
  }
}
</script>

<style lang="sass" scoped>
@import 'assets/kapiche.sass'

.dot-container
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 0 26px
  margin: 20px 0

.dots-grid
  display: grid
  grid-template-columns: repeat(auto-fit, minmax(250px, 1fr))
  gap: 6px
  width: 100%

.dot-item
  display: flex
  align-items: center
  min-width: 0

  .number
    color: $subdued
    width: 15px
    text-align: right
    font-weight: bold
    font-size: 13px
    direction: rtl

  .dot
    $size: 10px
    width: $size
    min-width: $size
    height: $size
    border-radius: 50%
    margin: 0 8px

  .label
    font-size: 16px
    white-space: nowrap
    overflow: hidden
    text-overflow: ellipsis
    flex-grow: 1

button
  margin-top: 20px
  padding: 0
  background-color: none
  color: $blue
  border: none
  border-radius: 4px
  cursor: pointer
  font-weight: bold
  font-size: 14px
</style>