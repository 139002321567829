import { TrendLine } from "src/types/widgets.types"
import ChartUtils from 'src/utils/chart'

export const formatCsvData = (
  data: TrendLine[],
  dateField: string,
  resolution: string,
  aggMethod: string,
) => {
  const dataByDate: {
    [date: number]: {
      [name: string]: string
    }
  } = {}

  data.forEach((s) => {
    s.datetimes.forEach((d: number, j: number) => {
      if (!dataByDate[d]) dataByDate[d] = {}
      dataByDate[d][s.name] = s.counts[j].toFixed(2)
    })
  })

  return Object.keys(dataByDate).sort().map((d) => {
    const dateString = ChartUtils.formatDate(d, resolution.toLowerCase())
    const row = { [dateField]: dateString }
    data.forEach(({ name }) => {
      row[`${name} (${aggMethod})`] = dataByDate[+d][name] ?? ''
    })
    return row
  })
}