import { SchemaColumn } from "src/types/SchemaTypes"

// Regex to detect top/bot x display values including impact conditions.
export const topBoxRegex = /__score__(impact__)?top_box_(\d+)__(.*)/
export const botBoxRegex = /__score__(impact__)?bot_box_(\d+)__(.*)/

// Regex to match other aggregation values including impact conditions.
export const aggRegex = /__score__(impact__)?(average|median|sum)__(.*)/

// Function to generate scoreOptions, intended to be provided to makeRequirements function.
export const getScoreOptionsForRequirements = (display: string, scoreColumns: SchemaColumn[]) => {
  let scoreOptions: {
  name?: string,
  agg?: string,
  boxVal?: number,
  range?: Array<number>
  excludeOutOfRange?: boolean
  } = {}
  if (scoreColumns.length === 0) return scoreOptions
  if (display.startsWith('__score__')) {
    if (display.match(aggRegex)) {
      let ops = display.match(aggRegex)
      if (!ops) return {}
      scoreOptions["name"] = ops[3]
      scoreOptions["agg"] = ops[2] === "average"? "mean": ops[2]
    } else if (display.match(topBoxRegex)) {
      let ops = display.match(topBoxRegex)
      if (!ops) return {}
      scoreOptions["name"] = ops[3]
      scoreOptions["agg"] = "top x box"
      scoreOptions["boxVal"] = parseInt(ops[2])
    }  else if (display.match(botBoxRegex)) {
      let ops = display.match(botBoxRegex)
      if (!ops) return {}
      scoreOptions["name"] = ops[3]
      scoreOptions["agg"] = "bot x box"
      scoreOptions["boxVal"] = parseInt(ops[2])
    }
    const scoreCol = scoreColumns.filter(col => col.name === scoreOptions.name)[0]
    scoreOptions["range"] = scoreCol.score_range
    scoreOptions["excludeOutOfRange"] = scoreCol.exclude_out_of_range
  }
  return scoreOptions
}

// Function to detect score display value, and return the corresponding display value to be stored
// in the dashboard config.
// In the dashboard config, we only store the fact that the type of column is score and the column
// name. We donot store the aggregation method since that can easily be changed.
export const formatDisplayForConfig = (display: string) => {
  let formattedDisplay = display
  if (display.startsWith("__score")) {
    if (display.match(aggRegex)) {
      let ops = display.match(aggRegex)
      if (!ops) return ""
      if (ops[1] === "impact__") {
        formattedDisplay = `__score__impact__${ops[3]}`
      } else {
        formattedDisplay = `__score__${ops[3]}`
      }
    } else if (display.match(topBoxRegex)) {
      const ops = display.match(topBoxRegex)
      if (!ops) return ""
      if (ops[1] === "impact__") {
        formattedDisplay = `__score__impact__${ops[3]}`
      } else {
        formattedDisplay = `__score__${ops[3]}`
      }
    } else if (display.match(botBoxRegex)) {
      const ops = display.match(botBoxRegex)
      if (!ops) return ""
      if (ops[1] === "impact__") {
        formattedDisplay = `__score__impact__${ops[3]}`
      } else {
        formattedDisplay = `__score__${ops[3]}`
      }
    }
  }
  return formattedDisplay
}

// Function to format display for labels like on the tooltips
export const formatDisplayForLabel = (display: string) => {
  let formattedDisplay = display
  if (display.startsWith("__score")) {
    if (display.match(aggRegex)) {
      let ops = display.match(aggRegex)
      if (!ops) return ""
      if (ops[1] === "impact__") {
        formattedDisplay = `Impact on ${ops[2]} ${ops[3]}`
      } else {
        formattedDisplay = `${ops[2]} ${ops[3]}`
      }
    } else if (display.match(topBoxRegex)) {
      const ops = display.match(topBoxRegex)
      if (!ops) return ""
      if (ops[1] === "impact__") {
        formattedDisplay = `Impact on Top ${ops[2]} Box ${ops[3]}`
      } else {
        formattedDisplay = `Top ${ops[2]} Box ${ops[3]}`
      }
    } else if (display.match(botBoxRegex)) {
      const ops = display.match(botBoxRegex)
      if (!ops) return ""
      if (ops[1] === "impact__") {
        formattedDisplay = `Impact on Bottom ${ops[2]} Box ${ops[3]}`
      } else {
        formattedDisplay = `Bottom ${ops[2]} Box ${ops[3]}`
      }
    }
  }
  return formattedDisplay
}

// Get headers for ThemeWidgets char based on display value.
export const getScoreHeaders = (label: string, display: string, showingExpected: boolean) => {
  if (display.match(aggRegex)) {
      let aggMap: Record<string, string> = {
          "average": "Average",
          "sum": "Sum",
          "median": "Median",
      }
      const ops = display.match(aggRegex)
      if (!ops) return []
      const agg = aggMap[ops[2]]
      const impactString = ops[1] === "impact__"? "Impact on ": ""
      const header = showingExpected?
      [
        {label, sortable: true, sortAsc: null, },
        {sortable: true, sortAsc: null, label: `${impactString}${agg} ${ops[3]}`}
      ]:
      [
        {label, sortable: true, sortAsc: null, },
        {sortable: true, sortAsc: null, label: `Impact on ${agg} ${ops[3]}`},
        {sortable: true, sortAsc: null, label: `${agg} ${ops[3]}`}
      ]
      return header
  } else if (display.match(topBoxRegex)) {
    const ops = display.match(topBoxRegex)
    if (!ops) return []
    const impactString = ops[1] === "impact__"? "Impact on ": ""
    const header = showingExpected?
    [
        {label, sortable: true, sortAsc: null, },
        {sortable: true, sortAsc: null, label: `${impactString}Top ${ops[2]} Box ${ops[3]}`}
    ]:
    [
      {label, sortable: true, sortAsc: null, },
      {sortable: true, sortAsc: null, label: `Impact on Top ${ops[2]} Box ${ops[3]}`},
      {sortable: true, sortAsc: null, label: `Top ${ops[2]} Box ${ops[3]}`}
    ]
    return header
  } else if (display.match(botBoxRegex)) {
    const ops = display.match(botBoxRegex)
    if (!ops) return []
    const impactString = ops[1] === "impact__"? "Impact on ": ""
    const header = showingExpected?
    [
        {label, sortable: true, sortAsc: null, },
        {sortable: true, sortAsc: null, label: `${impactString}Bottom ${ops[2]} Box ${ops[3]}`}
    ]:
    [
      {label, sortable: true, sortAsc: null, },
      {sortable: true, sortAsc: null, label: `Impact on Bottom ${ops[2]} Box ${ops[3]}`},
      {sortable: true, sortAsc: null, label: `Bottom ${ops[2]} Box ${ops[3]}`}
    ]
    return header
  }
}
