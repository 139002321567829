import { ChrysalisFilter } from "src/types/DashboardFilters.types"
import { ScoreColumn } from "src/utils/score"

const topBoxRegex = /top (\d+) box/
const botBoxRegex = /bot (\d+) box/

export const getScoreColumnRegroupMap = (cols: ScoreColumn[]) => {
  let mappedCols: Record<string, Record<string, string>> = {
    'standard': {},
    'impact': {}
  }
  for (let col of cols) {
    if (['top box', 'bot box'].includes(col.aggregation.type)) {
        mappedCols['standard'][`${col.name}|box%__`] = `Score ${col.aggregation.title} ${col.name}`
        mappedCols['impact'][`${col.name}|box%i_rto__`] = `Impact On ${col.aggregation.title} ${col.name}`
    } else {
      // TODO: adjust for aggval type output
        mappedCols['standard']["aggVal|mean__"] = `Score ${col.aggregation.title} ${col.name}`
        mappedCols['impact']["aggVal|mean__i_rto__"] = `Impact On ${col.aggregation.title} ${col.name}`
    }
  }
  return mappedCols
}

export const generateScoreRequirements = (scoreColumns: ScoreColumn[], selectedDisplay: string) => {
  if (scoreColumns.length === 0) return {}
  const scoreNames = scoreColumns.map((col) => col.name)

  if (scoreNames.includes(selectedDisplay)) {
    const selectedScoreColumn = scoreColumns.find(
      (col) => col.name === selectedDisplay
    )
    if (!selectedScoreColumn) return {}
    if (selectedScoreColumn?.aggregation) {
      if (["average", "median", "sum"].includes(selectedScoreColumn.aggregation.type)) {
        return {
        'aggfuncs': [
          {
            'new_column': 'aggVal|count',
            'src_column': selectedScoreColumn.name,
            'aggfunc': 'count'
          }, {
            'new_column': 'aggVal|mean__',
            'src_column': selectedScoreColumn.name,
            'aggfunc': selectedScoreColumn.aggregation.type == "average"?  "mean": selectedScoreColumn.aggregation.type,
          }
        ],
        'metric_calculator': 'mean_impact',
        }
      } else if (['top box', 'bot box'].includes(selectedScoreColumn.aggregation.type) &&
        selectedScoreColumn.aggregation.boxVal) {
        // first we calculate the box Values for the top/bot x agg.
        let boxValues = []
        let boxVal = selectedScoreColumn.aggregation.boxVal
        let scoreRange = selectedScoreColumn.range
        if (selectedScoreColumn.aggregation.type === "top box") {
          for (let i=scoreRange[1]; i > scoreRange[1]-boxVal; i--) {
            boxValues.push(i)
          }
        } else {
          // Assuming bot x box here.
          for (let i: number = scoreRange[0]; i < scoreRange[0]+boxVal; i++) {
            boxValues.push(i)
          }
        }
        return {
          'pivot_field': selectedScoreColumn.name,
          'aggfuncs': [{
            'new_column': 'frequency',
            'src_column': 'document_id',
            'aggfunc': 'count',
          }],
          'metric_calculator': {
            'type': 'box',
            'field': selectedScoreColumn.name,
            'impact': true,
            'box_values': boxValues,
          },
        }
      } else return {}
    } else return {}
  } else return {}
}

export const generateScoreFilters = (scoreColumns: ScoreColumn[], selectedDisplay: string): ChrysalisFilter[] => {
  let filters: ChrysalisFilter[] = []
  if (scoreColumns.length === 0) return filters
  const scoreNames = scoreColumns.map((col) => col.name)
  if (scoreNames.includes(selectedDisplay)) {
    const selectedScoreColumn = scoreColumns.find(
      (col) => col.name === selectedDisplay
    )
    if (!selectedScoreColumn) return filters
    if (selectedScoreColumn.excludeOutOfRange) {
      filters.push({field: selectedScoreColumn.name, op: '>=', value: selectedScoreColumn.range[0]})
      filters.push({field: selectedScoreColumn.name, op: '<=', value: selectedScoreColumn.range[1]})
    }
  }
  return filters
}

