<template>
  <div ref="root" :class="rootClasses" class="dropdown" tabindex="0" @keyup="handleKeyup">
    <div
      role="button"
      class="dropdown-trigger"
      aria-haspopup="true"
      @click="toggle"
    >
      <slot name="trigger" />
    </div>
    <transition :name="animation">
      <div class="dropdown-menu" :class="{adjusting: adjusting && bounded}">
        <div
          v-show="isActive"
          ref="content"
          :class="['dropdown-content', { 'no-padding': noPadding }]"
          :style="{'max-height':maxHeight, 'max-width':maxWidth}"
        >
          <slot :close="close" :is-active="isActive" />
        </div>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from 'vue'
const DEFAULT_CLOSE_OPTIONS = ['escape', 'outside']

export default defineComponent({
  inject: {
    getBounds:{
      from: 'getBounds',
      default: ()=>null
    }
  },
  props: {
    modelValue: {
      type: [String, Number, Boolean] as PropType<string | number | boolean | null>,
      default: null
    },
    disabled: { type: Boolean, required: false, default: false },
    hoverable:  { type: Boolean, required: false, default: false },
    inline:  { type: Boolean, required: false, default: false },
    /* Automatically select the first dropdown item matching key pressed */
    allowKeySelect: { type: Boolean, default: true },
    animation: { type: String, default: 'fade' },
    closeOnClick: { type: Boolean, default: true },
    canClose: { type: [Array, Boolean], default: true },
    position: { type: String, default: 'is-bottom-left', validator (value) {
        return [
          'is-top-right',
          'is-top-left',
          'is-bottom-left',
          'is-bottom-right'
        ].indexOf(value) > -1
      }
    },
    expanded: { type: Boolean, required: false, default: false },
    highlightActive: { type: Boolean, required: false, default: true },
    bounded: { type: Boolean, required: false, default: false },
    noPadding: { type: Boolean, required: false, default: false },
  },
  data () {
    return {
      selected: this.modelValue,
      isActive: false,
      isHoverable: this.hoverable,
      forceUp: false,
      adjusting: false,
      maxHeight: '100%',
      maxWidth: '100%',
    }
  },
  computed: {
    rootClasses () {
      return [this.position, {
        'disabled': this.disabled,
        'hoverable': this.hoverable,
        'inline': this.inline,
        'active': this.isActive || this.inline,
        'expanded': this.expanded,
        'left': ['is-top-left', 'is-bottom-left'].includes(this.position),
        'right': ['is-top-right', 'is-bottom-right'].includes(this.position),
        'up': this.forceUp || ['is-top-right', 'is-top-left'].includes(this.position),
        'bottom': ['is-bottom-right', 'is-bottom-left'].includes(this.position),
      }]
    },
    cancelOptions () {
      return typeof this.canClose === 'boolean'
        ? this.canClose
          ? DEFAULT_CLOSE_OPTIONS
          : []
        : this.canClose
    },
  },
  watch: {
    /**
     * When v-model is changed set the new selected item.
     */
     modelValue (value) {
      this.selected = value
    },
    /**
     * Emit event when isActive value is changed.
     */
    isActive (value) {
      this.$emit('active-change', value)
    }
  },
  created () {
    if (typeof window !== 'undefined') {
      document.addEventListener('click', this.clickedOutside, true)
      document.addEventListener('keyup', this.keyPress, true)
      document.addEventListener('dropdownOpened', this.otherDropdownOpened.bind(this))
    }
  },
  beforeUnmount () {
    if (typeof window !== 'undefined') {
      document.removeEventListener('click', this.clickedOutside)
      document.removeEventListener('keyup', this.keyPress)
      document.removeEventListener('dropdownOpened', this.otherDropdownOpened)
    }
  },
  methods: {
    otherDropdownOpened (e) {
      if (e.detail.dropdown !== this) {
        this.close()
      }
    },
    /**
     * Close dropdown if clicked outside.
     */
    clickedOutside (e: MouseEvent) {
      if (!this.isActive || !this.$refs['root']) return
      if (this.cancelOptions.indexOf('outside') < 0) return
      const contained = this.$refs['root'].contains(e.target)
      if (!contained) {
        this.close()
      }
    },

    close () {
      this.isActive = false
      this.$emit('cancelled')
    },

    /**
     * Select / iterate child items based on the first
     * character of their inner text value.
     */
    handleKeyup (event) {
      if (!this.allowKeySelect) {
        return
      }
      const searchKey = event.key.toLowerCase()
      if (!searchKey.match(/^[a-zA-Z0-9]{1}$/)) {
        // Bail out for non alphanumeric key presses (e.g. esc)
        return
      }
      const children = this.$refs.content.children
      for (var i = 0; i < children.length; i++) {
        const c = children[i]
        if (c.classList.contains('active')) {
          // item is already selected; skipping allows us to iterate
          // to another item starting with the same letter
          continue
        }
        if (c.innerText.trim().toLowerCase().startsWith(searchKey)) {
          // Simulate click to select item
          c.click()
          break
        }
      }
    },

    /**
     * Keypress event that is bound to the document
     */
    keyPress (event) {
      // Esc key
      if (this.isActive && event.keyCode === 27) {
        if (this.cancelOptions.indexOf('escape') < 0) return
        this.isActive = false
        this.$emit('cancelled')
      }
    },

    /**
     * Toggle dropdown if it's not disabled.
     */
    toggle (e: Event) {
      e.stopPropagation()

      if (this.disabled) return
      // while adjusting is true the menu opacity is 0 to hide the flip
      this.adjusting = true

      if (!this.isActive) {

        // if not active, toggle after clickOutside event
        // this fixes toggling programmatic
        this.$nextTick(() => {
          const value = !this.isActive
          this.isActive = value
          setTimeout(() => {
            this.isActive = value
            this.$emit('toggled', this.isActive, e)
            this.setAdjustment()
          })
        })
        document.dispatchEvent(
          new CustomEvent('dropdownOpened', {
            detail: {
              dropdown: this,
            }
          })
        )
      } else {
        this.isActive = !this.isActive
        this.$emit('toggled', this.isActive, e)
      }
    },
    setAdjustment () {
      //only do this if prop specifies
      if (!this.bounded) return
      this.forceUp = false
      this.$nextTick(()=>{
        // set menu to default height
        this.maxHeight = "100%"
        this.maxWidth = "100%"
        // get our bounding element, return if null
        const areaBound = this.getBounds()
        if (!areaBound) return

        const scrollArea = areaBound.getBoundingClientRect()
        const menuArea = this.$el.getBoundingClientRect()

        // determine height of menu & space above & below it in bound
        const menuHeight = this.$el.scrollHeight
        const topArea = menuArea.top - scrollArea.top
        const bottomArea = scrollArea.bottom - menuArea.bottom

        // IF menu is taller than area below it AND the area above it is larger than that below:
        // => flip the menu and resize the menu to fit the above area
        // BUT if the area area above is not larger than that below:
        // => resize the menu to fit the below area
        // we leave a small space (13px) to ensure it is clear where the bottom of the menu is
        // 13px is arbitrary
        if (menuHeight > bottomArea) {
          if (topArea > bottomArea) {
            this.forceUp = true
            this.maxHeight = `${topArea - 13}px`
          } else {
            this.maxHeight = `${bottomArea - 13}px`
          }
        }
        const maxWidth = scrollArea.right - menuArea.left - 13
        this.maxWidth = `${maxWidth}px`
        this.adjusting = false
      })
    },

    /**
     * Click listener from DropdownItem.
     *   1. Set new selected item.
     *   2. Emit input event to update the user v-model.
     *   3. Close the dropdown.
     */
    selectItem (value) {
      if (this.selected !== value) {
        this.selected = value
        this.$emit('change', this.selected)
      }
      this.$emit('input', this.selected)
      this.$emit('update:modelValue', this.selected)
      this.isActive = !this.closeOnClick
      if (this.hoverable && this.closeOnClick) {
        this.isHoverable = false
        // Timeout for the animation complete before destroying
        setTimeout(() => {
          this.isHoverable = true
        }, 250)
      }
    }
  }
})
</script>

<style lang="sass" scoped>
@import "assets/kapiche.sass"

$dropdown-menu-min-width: 12rem !default

$dropdown-content-background-color: $white !default
$dropdown-content-arrow: $blue !default
$dropdown-content-offset: 4px !default
$dropdown-content-padding-bottom: 0.5rem !default
$dropdown-content-padding-top: 0.5rem !default
$dropdown-content-radius: 4px !default
$dropdown-content-shadow: 0 0.5em 1em -0.125em rgba($shadow-colour, 0.1) !default
$dropdown-content-z: 7 !default

.dropdown
  display: inline-flex
  position: relative
  vertical-align: top
  &.active,
  &.hoverable:hover
    .dropdown-menu
      display: flex
      flex-direction: column
  &.right
    .dropdown-menu
      left: auto
      right: 0
  &.left
    .dropdown-menu
      left: 0
      right: auto
  &.up
    .dropdown-menu
      bottom: 100%
      top: auto
    .dropdown-content
      padding-bottom: $dropdown-content-offset
      padding-top: initial

  .dropdown-menu
    display: none
    left: 0
    min-width: $dropdown-menu-min-width
    // calculate a max-height based off max height underneath dashboard headers with some padding
    max-height: calc(100vh - 160px)
    position: absolute
    top: 100%
    z-index: $dropdown-content-z

  .dropdown-content
    display: flex
    flex-direction: column
    height: 100%
    overflow-y: auto

  .dropdown-menu
    margin-top: $dropdown-content-offset
    background-color: $dropdown-content-background-color
    border-radius: $dropdown-content-radius
    box-shadow: $dropdown-content-shadow
  .dropdown-content
    padding-bottom: $dropdown-content-padding-bottom
    padding-top: $dropdown-content-padding-top
    &.no-padding
      padding-bottom: 0
      padding-top: 0

  .adjusting
    visibility: hidden

  .dropdown-trigger
    overflow: hidden
</style>
