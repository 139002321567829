import { AnyWidgetConfig, DashboardConfig, DashboardView, WidgetName, DateRangeTypeEnum } from 'types/DashboardTypes'
import { DashboardSavedQuery, QueryRow, SavedQuery } from 'types/Query.types'
import { ChrysalisFilter } from 'types/DashboardFilters.types'
import { SchemaColumn } from 'src/types/SchemaTypes'
import { Component } from 'vue'
import { capitalize, cloneDeep, union, flatten } from 'lodash'
import {
  ContextNetwork,
  SegmentCorrelation,
  EmergentConcepts,
  SegmentationWidget,
  NpsTimeline,
  QuadrantWidget,
  QueryDetails,
  SentimentTimeline,
  CompareSentimentTimeline,
  ThemesWidget,
  Timeline,
  VerbatimsWidget,
  PivotTable,
  KeyPhrases,
  CompareSegmentationWidget,
  CompareThemesWidget,
  CompareTimeline,
  CompareNPSTimeline,
  AiSummary,
  ScoreTimeline,
  CompareScoreTimeline,
} from 'src/components/DataWidgets'
import { filterToRange } from 'src/utils/dates'
import { RouteLocation } from 'vue-router'
import QueryUtils, { expandQuery } from 'src/utils/query'

/**
 * This function replaces empty segment values in dashboard filters
 * with the special `'(No Value)'` string token that is recognized
 * by chrysalis for empty segments.
 *
 * Note that it is not only segment filters that travel through
 * here: date filters do as well, and crucially, their values
 * are never arrays. This is why the function internally checks
 * to see whether the value is an array or not.
 */
export const processFilters = (filters: ChrysalisFilter[]): ChrysalisFilter[] => {
  return filters.map((filter) => {
    let newFilter = cloneDeep(filter)
    let replacer = (v: string) => v === '' ? '(No Value)' : v
    if (Array.isArray(newFilter.value)) {
      newFilter.value = newFilter.value.map(replacer)
    } else {
      newFilter.value = replacer(newFilter.value as string)
    }
    return newFilter
  })
}

/**
 * Simplify query objects to the properties required by various
 * dashboard functions.
 */
export const processQueries = (queries: SavedQuery[] | DashboardSavedQuery[]): DashboardSavedQuery[] => {
  return queries.map((q) => ({
    query_value: q.query_value,
    name: q.name,
    id: q.id,
  }))
}

export const defaultConfig = (): DashboardConfig => ({
  queryRows: [],
  compareQueryRows: [],
  compareMode: false,
  showGroupLabels: true,
  dateRange: { type: DateRangeTypeEnum.ALL_TIME },
  widgets: {
    overview: [
      { name: 'nps-timeline', visible: true, options: {} },
      { name: 'sentiment-timeline', visible: true, options: {} },
      { name: 'themes-concepts', visible: true, options: {} },
      { name: 'quadrant', visible: true, options: {} },
      { name: 'timeline', visible: true, options: {} },
      { name: 'segments', visible: true, options: {} },
      { name: 'emergent-concepts', visible: true, options: {} },
      { name: 'pivot-table', visible: true, options: {} },
      { name: 'segment-correlation', visible: true, options: {} },
      { name: 'compare-segments', visible: true, options: {} },
      { name: 'compare-themes-concepts', visible: true, options: {} },
      { name: 'compare-sentiment-timeline', visible: true, options: {} },
      { name: 'compare-timeline', visible: true, options: {} },
      { name: 'compare-nps-timeline', visible: true, options: {} },
      { name: 'compare-score-timeline', visible: true, options: {} },
      { name: 'score-timeline', visible: true, options: {} },
    ],
    drilldown: [
      { name: 'nps-timeline', visible: true, options: {} },
      { name: 'sentiment-timeline', visible: true, options: {} },
      { name: 'context-network', visible: true, options: {} },
      { name: 'verbatims', visible: true, options: {} },
      { name: 'timeline', visible: true, options: {} },
      { name: 'query-details', visible: true, options: {} },
      { name: 'segments', visible: true, options: {} },
      { name: 'emergent-concepts', visible: true, options: {} },
      { name: 'key-phrases', visible: true, options: {} },
      { name: 'ai-summary', visible: true, options: {} },
      { name: 'score-timeline', visible: true, options: {} },
    ],
  },
})

/**
 * Format variables as a Dashboard config JSON object.
 */
export const generateConfig = (
  widgets = defaultConfig().widgets,
  dateRange = defaultConfig().dateRange,
  queryRows = defaultConfig().queryRows,
  compareQueryRows = defaultConfig().compareQueryRows,
  compareMode = defaultConfig().compareMode,
  showGroupLabels = defaultConfig().showGroupLabels,
): DashboardConfig => {
  return {
    widgets,
    dateRange,
    queryRows,
    compareQueryRows,
    compareMode,
    showGroupLabels,
  }
}

/**
 * Convert a config object to usable components.
 */
export const parseConfig = (config: DashboardConfig, schema: SchemaColumn[], defaultDateField: string, featureFlags: Record<string, boolean> = {}, aiUse: Record<string, boolean> = {}) => {
  const localConfigCopy = cloneDeep(config)
  let dateRange = localConfigCopy.dateRange ?? defaultConfig().dateRange
  let widgets = localConfigCopy.widgets ?? defaultConfig().widgets

  let filters = localConfigCopy.filters ?? []
  let queryRows = localConfigCopy.queryRows ?? defaultConfig().queryRows
  let compareQueryRows = localConfigCopy.compareQueryRows ?? defaultConfig().compareQueryRows

  if (featureFlags.scoring_metrics) {
    // Add score-timeline config if it doesn't exist
    if (!widgets.overview.find(({ name }) => name === 'score-timeline')) {
      widgets.overview.push({ name: 'score-timeline', visible: true, options: {} })
    }
    if (!widgets.drilldown.find(({ name }) => name === 'score-timeline')) {
      widgets.drilldown.push({ name: 'score-timeline', visible: true, options: {} })
    }
  } else {
    widgets.overview = widgets.overview.filter((c) => c.name !== 'score-timeline')
    widgets.drilldown = widgets.drilldown.filter((c) => c.name !== 'score-timeline')
  }

  if (aiUse) {
    // Add summary config if it doesn't exist
    if (!widgets.drilldown.find(({ name }) => name === 'ai-summary')) {
      widgets.drilldown.push({ name: 'ai-summary', visible: true, options: {} })
    }
  }

  // Add compare-nps-timeline config if it doesn't exist
  if (!widgets.overview.find(({ name }) => name === 'compare-nps-timeline')) {
    widgets.overview.push({ name: 'compare-nps-timeline', visible: true, options: {} })
  }

  // Add compare-score-timeline config if it doesn't exist
  if (!widgets.overview.find(({ name }) => name === 'compare-score-timeline')) {
    widgets.overview.push({ name: 'compare-score-timeline', visible: true, options: {} })
  }

  // Add compare-timeline config if it doesn't exist
  if (!widgets.overview.find(({ name }) => name === 'compare-timeline')) {
    widgets.overview.push({ name: 'compare-timeline', visible: true, options: {} })
  }

  // Add compare-sentiment-timeline config if it doesn't exist
  if (!widgets.overview.find(({ name }) => name === 'compare-sentiment-timeline')) {
    widgets.overview.push({ name: 'compare-sentiment-timeline', visible: true, options: {} })
  }

  // Add compare-segments config if it doesn't exist
  if (!widgets.overview.find(({ name }) => name === 'compare-segments')) {
    widgets.overview.push({ name: 'compare-segments', visible: true, options: {} })
  }

  // Add compare-themes-concepts config if it doesn't exist
  if (!widgets.overview.find(({ name }) => name === 'compare-themes-concepts')) {
    widgets.overview.push({ name: 'compare-themes-concepts', visible: true, options: {} })
  }

  if (!widgets.overview.find(({ name }) => name === 'pivot-table')) {
    widgets.overview.push({ name: 'pivot-table', visible: true, options: {} })
  }

  if (!widgets.overview.find(({ name }) => name === 'segment-correlation')) {
    widgets.overview.push({ name: 'segment-correlation', visible: true, options: {} })
  }

  if (!widgets.drilldown.find(({ name }) => name === 'key-phrases')) {
    widgets.drilldown.push({ name: 'key-phrases', visible: true, options: {} })
  }

  // segments-compare was renamed to compare-segments for consistency,
  // so we need to remove the old name from the config if it exists
  widgets.overview = widgets.overview.filter((c) =>
    c.name !== 'segments-compare' as WidgetName
  )

  // need to figure out handling based on fflag
  const dateFields = schema.filter(s => ['DATE', 'DATE_TIME'].includes(s.typename)).map(s => s.name)
  const dateFilters = filters.filter((f: ChrysalisFilter) => dateFields.includes(f.field))
  const segmentFilters = filters.filter((f: ChrysalisFilter) => !dateFields.includes(f.field))

  if (!dateRange) {
    dateRange = { type: DateRangeTypeEnum.ALL_TIME, dateField: defaultDateField }
  }
  // ensure dateField is set
  if (!dateRange.dateField) {
    dateRange.dateField = defaultDateField
  }

  // old style to new style config
  if (dateFilters?.length) {
    // move filters to dateRange
    dateRange = filterToRange(dateFilters)
    filters = segmentFilters
  }

  // Convert nps-summary and sentiment-summary to their timeline versions
  const npsSummary = widgets.overview.find(({ name }) => name === 'nps-summary')
  if (npsSummary) {
    npsSummary.name = 'nps-timeline'
  }
  const sentimentSummary = widgets.overview.find(({ name }) => name === 'sentiment-summary')
  if (sentimentSummary) {
    sentimentSummary.name = 'sentiment-timeline'
  }

  // Convert old style filters to new query rows
  if (filters && filters.length > 0) {
    const converted = QueryUtils.convertDashboardFiltersToBotanicQueries(filters, [])
    queryRows = QueryUtils.botanicToQueryRows(converted) as QueryRow[]
    filters = []
  }

  return [
    dateRange,
    widgets,
    queryRows,
    compareQueryRows,
  ] as const
}

/**
 * Get a list of all widgets and whether they should be visible,
 * based on the Dashboard config, project attributes and view type.
 */
export const getVisibleWidgets = (
  config: DashboardConfig['widgets'],
  isView: DashboardView,
  hasSentiment: boolean,
  hasNPS: boolean,
  hasNumericFields: boolean,
  hasScoreFields: boolean,
  hasDate: boolean,
  zoomedWidget: WidgetName | null,
  compareMode: boolean,
  featureFlags: Record<string, boolean>,
  aiUse: boolean,
): Record<WidgetName, boolean> => {
  const canZoom = [
    'themes-concepts',
    'quadrant',
    'timeline',
    'segments',
    'emergent-concepts',
    'verbatims',
    'segment-correlation',
    'pivot-table',
    'key-phrases',
    'compare-themes-concepts',
    'compare-segments',
    'compare-timeline',
  ]

  const isZoomed = (name: string) =>
    isView['zoomed'] && zoomedWidget === name && canZoom.includes(name)

  const isVisible = (name: WidgetName, zoomName?: string): boolean => {
    zoomName ??= name
    const viewConfig = config?.[isView.overview ? 'overview' : 'drilldown'] as AnyWidgetConfig[]
    const visibleInConfig = viewConfig?.find((w) => w.name === name)?.visible
    return !!(visibleInConfig ?? false) && !isView['zoomed'] || isZoomed(zoomName)
  }

  return {
    'nps-summary': isVisible('nps-summary') && hasNPS && !compareMode,
    'sentiment-summary': isVisible('sentiment-summary') && hasSentiment && !compareMode,
    'nps-timeline': isVisible('nps-timeline') && hasNPS && !compareMode,
    'sentiment-timeline': isVisible('sentiment-timeline') && hasSentiment && !compareMode,
    'context-network': isVisible('context-network') && isView['drillDown'] && !compareMode,
    'verbatims': isVisible('verbatims') && isView['drillDown'] && !compareMode,
    'themes-concepts': isVisible('themes-concepts') && isView['overview'] && !compareMode,
    'key-phrases': isVisible('key-phrases') && isView['drillDown'] && !compareMode,
    'quadrant': isVisible('quadrant') && (hasNPS || hasSentiment || hasNumericFields) && isView['overview'] && !compareMode,
    'timeline': isVisible('timeline') && hasDate && !compareMode,
    'query-details': isVisible('query-details') && isView['query'] && !compareMode,
    'segments': isVisible('segments') && !compareMode,
    'emergent-concepts': isVisible('emergent-concepts') && hasDate && !compareMode,
    'pivot-table': isVisible('pivot-table') && isView['overview'] && !compareMode,
    'segment-correlation': isVisible('segment-correlation') && isView['overview'] && !compareMode,
    'ai-summary': aiUse && isVisible('ai-summary') && isView['drillDown'],
    'score-timeline': featureFlags.scoring_metrics && (hasNumericFields || hasScoreFields) && isVisible('score-timeline') && !compareMode,
    'compare-nps-timeline': isVisible('nps-timeline', 'compare-nps-timeline') && hasNPS && compareMode,
    'compare-score-timeline': featureFlags.scoring_metrics && (hasNumericFields || hasScoreFields) && isVisible('score-timeline', 'compare-score-timeline') && compareMode,
    'compare-sentiment-timeline': isVisible('sentiment-timeline', 'compare-sentiment-timeline') && hasSentiment && compareMode,
    'compare-themes-concepts': isVisible('themes-concepts', 'compare-themes-concepts') && isView['overview'] && compareMode,
    'compare-timeline': isVisible('timeline', 'compare-timeline') && hasDate && compareMode,
    'compare-segments': isVisible('segments', 'compare-segments') && compareMode,
  }
}

/**
 * Each widget has a "weight" that indicates how much space it
 * will take up. A widget with a higher weight will more likely
 * be pushed to the next column, in an attempt to keep them roughly
 * equal in height.
 */
export const allWidgets: Array<{
  name: WidgetName,
  component: Component,
  weight: number,
}> = [
    {
      name: 'ai-summary',
      component: AiSummary,
      weight: 2,
    },
    {
      name: 'nps-timeline',
      component: NpsTimeline,
      weight: 2,
    }, {
      name: 'compare-nps-timeline',
      component: CompareNPSTimeline,
      weight: 2,
    }, {
      name: 'compare-score-timeline',
      component: CompareScoreTimeline,
      weight: 2,
    }, {
      name: 'sentiment-timeline',
      component: SentimentTimeline,
      weight: 2,
    }, {
      name: 'compare-sentiment-timeline',
      component: CompareSentimentTimeline,
      weight: 2,
    }, {
      name: 'score-timeline',
      component: ScoreTimeline,
      weight: 2,
    }, {
      name: 'context-network',
      component: ContextNetwork,
      weight: 3,
    }, {
      name: 'verbatims',
      component: VerbatimsWidget,
      weight: 4,
    }, {
      name: 'themes-concepts',
      component: ThemesWidget,
      weight: 3,
    }, {
      name: 'compare-themes-concepts',
      component: CompareThemesWidget,
      weight: 3,
    }, {
      name: 'segment-correlation',
      component: SegmentCorrelation,
      weight: 3,
    }, {
      name: 'key-phrases',
      component: KeyPhrases,
      weight: 3,
    }, {
      name: 'pivot-table',
      component: PivotTable,
      weight: 1,
    }, {
      name: 'quadrant',
      component: QuadrantWidget,
      weight: 3,
    }, {
      name: 'timeline',
      component: Timeline,
      weight: 3,
    }, {
      name: 'compare-timeline',
      component: CompareTimeline,
      weight: 3,
    }, {
      name: 'query-details',
      component: QueryDetails,
      weight: 2,
    }, {
      name: 'segments',
      component: SegmentationWidget,
      weight: 3,
    }, {
      name: 'compare-segments',
      component: CompareSegmentationWidget,
      weight: 3,
    }, {
      name: 'emergent-concepts',
      component: EmergentConcepts,
      weight: 3,
    },
  ]

export const widgetNameToLabel = (name: WidgetName): string => {
  const labels: Record<string, string> = {
    'nps-timeline': 'NPS',
    'compare-nps-timeline': 'NPS Comparison',
    'compare-score-timeline': 'Score Comparison',
    'sentiment-timeline': 'Sentiment',
    'compare-sentiment-timeline': 'Sentiment Comparison',
    'themes-concepts': 'Themes & Concepts',
    'compare-themes-concepts': 'Themes & Concepts Comparison',
    'emergent-concepts': 'Emergent Concepts',
    'context-network': 'Context Network',
    'query-details': 'Theme Details',
    'quadrant': 'Quadrant Chart',
    'segments': 'Segmentation',
    'compare-segments': 'Segmentation Comparison',
    'segment-correlation': 'Segment Correlation',
    'pivot-table': 'Pivot Table',
    'key-phrases': 'Key Phrases',
    'compare-timeline': 'Timeline Comparison',
    'ai-summary': 'AI Summary',
    'score-timeline': 'Score Timeline',
  }
  return labels[name] ?? capitalize(name)
}

/**
 * This method is a helper to add a new query value to the query param
 * on VueRouter's route object. Where "key" is the name of the query param,
 * query.key can return:
 *   * undefined if the value "key" is not in the url
 *   * a single string if the value "key" occurs once in the url
 *   * a string[] if the value "key" occurs more than once in the url
 */
export const addValueToQueryParam = (
  route: RouteLocation,
  paramName: string,
  newValue: string
): string[] => {
  let urlValues = (route.query?.[paramName] ?? []) as string | string[]
  return union(flatten([urlValues]), [newValue])
}

export interface Theme {
  id: number
  name: string
  type: 'theme'
  is_new?: boolean
}

export interface Group {
  id: number
  name: string
  type: 'group'
  is_new?: boolean
  children: (Group | Theme)[]
}

export type GroupOrTheme = Group | Theme


export interface ExpandedGroup {
  name: string
  id: number
  query_value: SavedQuery['query_value']
}

export const expandThemeGroup = (group: Group, savedQueries: SavedQuery[]): ExpandedGroup => {
  type QueryValue = SavedQuery['query_value']

  const collectThemes = (child: Group | Theme, themes: QueryValue[] = []): QueryValue[] => {
    if (child.type === 'theme') {
      const query = savedQueries.find((q) => q.id === child.id)
      if (query) {
        const expandedValue = expandQuery(query.name, query.query_value, savedQueries)
        themes.push(expandedValue)
      }
    } else if (child.type === 'group' && child.children) {
      child.children.forEach((c) => collectThemes(c, themes))
    }
    return themes
  }

  const themes: QueryValue[] = collectThemes(group)

  return {
    name: group.name,
    id: group.id,
    query_value: {
      level: 'sentence',
      type: 'match_any',
      excludes: [],
      includes: themes,
    },
  }
}


export const mapChildToGroupNames = (
  nodes: GroupOrTheme[],
  // Type has to be one or the other as theme and group IDs
  // are independent and can overlap.
  typeToMap: 'theme' | 'group',
  useIds = true,
): Record<string, string> => {
  // Create a map of child names or IDs (depending on the useIds boolean)
  // to their parent group names (all parents).
  const themeToGroupMap: Record<string, string> = {}

  const recurse = (currentNode: GroupOrTheme, parentGroupName: string | null) => {
    const addToMap = () => {
      if (!parentGroupName) return
      if (useIds) {
        themeToGroupMap[currentNode.id] = parentGroupName
      } else {
        themeToGroupMap[currentNode.name] = parentGroupName
      }
    }

    if (typeToMap === 'theme' && currentNode.type === 'theme') {
      addToMap()
    } else if (currentNode.type === 'group' && currentNode.children) {
      if (typeToMap === 'group') {
        addToMap()
      }
      for (const child of currentNode.children) {
        let label = currentNode.name
        if (parentGroupName !== null ) {
          label = `${parentGroupName} // ${label}`
        }
        recurse(child, label)
      }
    }
  }

  for (const node of nodes) {
    recurse(node, null)
  }

  return themeToGroupMap
}

export const themeSorterWithGroups = (themes: Theme[]|SavedQuery[], themeToGroupNameMap: Record<number, string>) => {
  return themes.toSorted((q1, q2) => {
    const g1 = themeToGroupNameMap[q1.id]
    const g2 = themeToGroupNameMap[q2.id]
    if (g1 === g2) {
      return q1.name.localeCompare(q2.name)
    }
    return (g1||'').localeCompare(g2||'')
  })
}


export const flattenThemeGroups = (nodes: Group[]): Group[] => {
  const themeGroupList: Group[] = []

  const recurse = (node: GroupOrTheme) => {
    if (node.type === 'group') {
      themeGroupList.push(node)
      if (node.children) {
        node.children.forEach(recurse)
      }
    }
  }

  nodes.forEach(n => recurse(n))
  return themeGroupList
}
